.title {
  font-size: 5rem;
  font-family: CI Special;
  display: flex;
  line-height: 1;
  position: relative;
}
.title--h1 {
  font-size: 4rem;
}
.title--h2 {
  font-size: 3rem;
}
.title--h3 {
  font-size: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .title--h3 {
    font-size: 1.62rem;
  }
}
.title--h4 {
  font-size: 1.5rem;
}
.title--h5 {
  font-size: 1.25rem;
}
@media only screen and (max-width: 37.5em) {
  .title--h5 {
    font-size: 1.4rem;
  }
}
.title--body {
  font-size: 1rem;
}
.title--disabled {
  color: #BBBDBD;
}
.title--crossed {
  text-decoration: line-through;
  color: #FF0000;
  font-weight: normal;
  font-size: 0.75rem;
  font-style: italic;
  padding-right: 0.3rem;
}
.title--with-action {
  cursor: pointer;
}
.title__main {
  width: 100%;
}
.title__sup {
  align-self: baseline;
  margin-left: 0.5rem;
  font-family: CI Special;
  font-size: 0.75rem;
  font-weight: normal;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  margin-top: 3px;
  font-size: 35%;
}
.title--body .title__sup {
  margin-top: 0;
  font-size: 0.75rem;
}
.title--h4 .title__sup {
  font-size: 0.75rem;
}
.title__subtitle {
  color: #858585;
  font-size: 0.75rem;
  font-family: CI;
  font-weight: normal;
  position: absolute;
  bottom: -1rem;
  left: 0;
  white-space: nowrap;
}
.title--invert-sup .title__sup {
  order: 1;
  margin-left: 0;
}
.title--invert-sup .title__main {
  order: 2;
  margin-left: 0.5rem;
}
.title--default {
  font-family: CI;
  font-weight: bold;
}

