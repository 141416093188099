@import "../../../../assets/scss/variables";

$digit-width: .54em;
$dot-width: .217em;
$color-back: $color-1;

.react-daterange-picker {
  display: inline-flex;
  position: relative;

  &--disabled {
    background-color: $color-1;
    color: $color-3;
  }

  &__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: thin solid $color-5;
  }

  &__inputGroup {
    min-width: calc((4px * 3) + #{$digit-width} * 8 + #{$dot-width} * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: $digit-width;
      height: 100%;
      position: relative;
      padding: 0 1px !important;
      border: 0;
      background: none!important;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &--hasLeadingZero {
        margin-left: -$digit-width;
        padding-left: calc(1px + #{$digit-width});
      }
    }
  }

  &__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover, &:focus {
        .react-daterange-picker__button__icon {
          stroke: $color-2;
        }
      }
    }

    &:disabled {
      .react-daterange-picker__button__icon {
        stroke: $color-5;
      }
    }

    svg {
      display: inherit;
    }
  }

  &__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    box-shadow: 0 4px 22px rgba(0, 0, 0, 0.2);

    &--closed {
      display: none;
    }
  }
}


.react-calendar {
  border: none;
}

.react-calendar__navigation__label,
.react-calendar__tile {
  font-size: .75rem;
  padding: 1rem 0;
  font-weight: $font-weight-bold;
}

.react-calendar__tile--now {
  background: $color-4;
  color: $color-1;
}

.react-calendar__tile--active {
  background: $color-2;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $color-2;
}

.react-calendar__month-view__days__day--weekend {
  color: currentColor;
}


.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}