@media only screen and (max-width: 68.75em) {
  .filter-wrapper {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
  }
}

.product-category .active-order {
  margin-bottom: 2rem;
}
.product-category .product-category-order {
  padding: 2rem 0;
  background: #633025;
  position: relative;
  overflow: hidden;
}
.product-category .product-category-order-image {
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(40%);
}
.product-category .product-category-order-image + div {
  position: relative;
}
.product-category .product-category-title {
  position: sticky;
  z-index: 5;
  padding-top: 0.5rem;
  background: #F5F5F5;
  top: 5rem;
}
@media only screen and (max-width: 37.5em) {
  .product-category .product-category-title {
    top: 7rem;
  }
}

.item-hidden {
  display: none;
}

.item-visible {
  display: flex;
}

[data-scrollspy-id] {
  display: none;
}
[data-scrollspy-id].active {
  display: flex;
}


