@import "../../assets/scss/variables";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-modal;
  width: 100%;
  height: 100%;
  background: rgba($color-3, 0.6);
  overflow: auto;

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    overflow: auto;
    background: $color-1;
    transition: min-width 0.2s ease-out;
    display: flex;
  }

  &__container {
    min-width: 12.5rem;
    position: relative;
    padding: 10% 4rem 12.5% 4rem;

    @include responsive($bp-sm) {
      max-width: 85vw;
    }

    > div {
      position: relative;
      z-index: 1;
    }

    > .button {
      position: absolute !important;
      z-index: 2;
      right: 0;
      top: 0;
      padding: 0 .5rem;
      color: $color-1;

      @include responsive($bp-sm) {
        top: -.5rem;
      }

      svg {
        width: 1rem;
      }
    }

    > img:nth-child(1) {
      z-index: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 12rem;
    }

    > img:nth-child(2) {
      //max-width: 40rem;
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
    }
  }
}