@import "../../assets/scss/variables";

.fab {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 auto;

  svg {
    width: 1.5rem;
  }

  &--variant-1 {
    background: $color-1;
  }

  &--variant-2 {
    background: $color-2;
  }

  &--variant-3 {
    background: $color-3;
  }

  &--variant-4 {
    background: $color-4;
  }

  &--variant-5 {
    background: $color-5;
  }

  &--variant-6 {
    background: $color-6;
  }

  &--variant-7 {
    background: $color-7;
  }

  &--variant-8 {
    background: $color-8;
  }
}