@import "../../assets/scss/variables";


.home {
  margin-top: -2rem;
  @include responsive($bp-sm) {
    margin-top: 0;
  }

  &__order-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  &__top {
    background-color: $color-1;
    overflow: hidden;

    .button--action {
      &:hover {
        background: $color-2;
        color: $color-3;
      }

      &:active {
        box-shadow: none;
      }
    }

    position: relative;

    > img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;

      &:nth-child(1) {
        top: -4rem;
      }

      &:nth-child(2) {
        bottom: -4rem;
      }
    }

    > div {
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "a b";
      grid-gap: 2rem;
      padding: 2rem;

      @include responsive($bp-lg) {
        display: block;
      }

    }
  }

  &__middle {
    padding: 4rem;

    & > .util {
      @include responsive($bp-md) {
        flex-direction: column;
        align-items: baseline;
      }
    }

    & > .util .title {
      margin-right: 2rem;
    }
  }
}