.availability-status {
  font-size: 0.75rem;
}
.availability-status--0 {
  color: #26BC7D;
}
.availability-status--1 {
  color: #FED33C;
}
.availability-status--2 {
  color: #FF0000;
}

