.dot {
  width: 0.75rem;
  height: 0.75rem;
  background: #FFFFFF;
  border-radius: 50%;
  display: inline-block;
  flex: none;
}
.dot--2 {
  background: #FF9F00;
}
.dot--4 {
  background: #633025;
}


