.billing-info{
  padding-bottom: .5rem;
  display: flex;
  overflow: auto;

  .title {
    margin-bottom: .5rem
  }

  & > div {
    margin-right: 2rem;
    flex: 1 0 10rem;
  }
}