@import "variables";

@mixin hiddenTooltip() {
  &:before,
  &:after {
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    pointer-events: none;
  }
}

@mixin visibleTooltip() {
  &:before,
  &:after {
    visibility: visible;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
  }
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;

  @include hiddenTooltip;

  &:before {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: .5rem;
    margin-left: -80px;
    padding: .5rem;
    width: 160px;
    background-color: rgba($color-3, 0.9);
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: .8rem;
    line-height: 1.2;
  }

  &:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    width: 0;
    border-top: .5rem solid rgba($color-3, 0.9);
    border-right: .5rem solid transparent;
    border-left: .5rem solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  &:hover {
    @include visibleTooltip
  }
}


[data-tooltip-visible] {
  @include visibleTooltip
}