@import "../../assets/scss/variables";

.order-page {

  .info .util > span {
    //line-height: .75;
    margin-left: .5rem;
  }

  &__bottom {
    display: flex;
  }

  &__bottom_left {
    width: 100%;
  }

  &__notes {
    max-width: 25rem;
  }

  &__bottom_right {
    @include responsive($bp-md) {
      width: 100%;
    }
  }
}