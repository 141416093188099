@import "../../../assets/scss/variables";

.menu-old {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-modal;
  width: 100%;
  height: 100%;
  background: rgba($color-3, 0.6);

  &__content {
    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    overflow: auto;
    background: $color-3;
    transition: min-width 0.2s ease-out;
    display: flex;
  }

  &__container {
    position: relative;
    color: $color-1;

    .links {
      min-width: 18rem;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 5rem 3rem 2rem 3rem;
    }

    .close-icon {
      position: absolute;
      top: 0;
      right: 0;
      color: $color-1;

      svg {
        width: 1.5rem;
      }
    }
  }

  &__container--first {
    .background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 15rem;
      text-align: right;
      margin-left: auto;
    }
  }

  &__container--second {
    .background:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }

    .background:nth-child(2) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &__link {
    text-align: left;
    padding: 0;
    box-sizing: border-box;
    background: transparent;
    border: none;
    font-size: 1rem;
    display: block;
    color: $color-1 !important;
    text-decoration: none;
    font-family: $font-2;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
      color: $color-2 !important
    }
  }

  .title {
    font-size: .75rem;
    color: $color-2;
    margin-bottom: 1.5rem;
  }

  &--mobile {
    display: none;
  }

  @include responsive($bp-sm) {
    display: none;
    &--mobile {
      display: flex;
    }
  }

  &--mobile &__content {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--mobile &__link {
    font-size: 1.5rem;
  }

  &--mobile &__container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-3;
    display: flex;
    flex-direction: column;

    .util {
      padding: 1.5rem;
    }

    .title {
      font-size: 1.25rem;
    }

    select {
      overflow: hidden;
      position: relative;
      background: transparent;
      color: $color-1;
      border: none;
      font-size: 1rem;
    }

    .links {
      padding: 5rem;
      font-size: 1.5rem;
    }

    .util {
      position: relative;
    }

    .close-icon {
      position: initial;

      svg {
        width: 2.5rem;
      }
    }

    .button--action {
      background-color: $color-2;
      color: $color-3;
      width: 100%;
      padding: 2rem;
      font-size: 1.5rem;
      max-width: 80%;
      margin: auto;
      display: block;
    }
  }
}