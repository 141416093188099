.make-favorite {
  line-height: initial;
  background: transparent;
  border: none;
  padding: 0.5rem 0.5rem;
  font-family: CI Special;
  font-size: 1.15rem;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #BBBDBD;
  opacity: 0.6;
}
@media print {
  .make-favorite {
    display: none;
  }
}
.make-favorite span {
  line-height: 1;
}
.make-favorite span:nth-child(2) {
  margin-left: 0.5rem;
}
.make-favorite:hover {
  opacity: 1;
}
.make-favorite--true {
  color: #0C1217;
  opacity: 1;
}
.make-favorite--true span:nth-child(2) {
  color: #FF0000;
}

.previewInterest {
  padding: 1rem 1rem 1rem 0;
  background: #FFFFFF;
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
  grid-gap: 0.5rem;
  text-align: left;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest {
    padding: 0;
    display: initial;
  }
}
.previewInterest__wrapper {
  display: flex;
  background: #FFFFFF;
  margin-bottom: 0.5rem;
  align-items: center;
  position: relative;
  text-align: left;
}
.previewInterest__wrapper--disabled {
  border: 1px solid #0C1217;
  background: transparent;
}
.previewInterest__wrapper--disabledFilled {
  background: #FFFFFF;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__wrapper {
    padding: 1rem;
    padding-bottom: 5rem;
    flex-direction: column;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    flex-wrap: wrap;
  }
}
.previewInterest__content {
  flex: 0 1 100%;
}
@media print {
  .previewInterest__label {
    display: none;
  }
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__label {
    position: absolute;
    top: 0;
    left: 0;
  }
  .previewInterest__label svg {
    display: block !important;
  }
  .previewInterest__label span {
    background: transparent !important;
    position: absolute;
    margin-left: 0.25rem;
    margin-top: 0.5rem;
    padding: 0 !important;
  }
}
.previewInterest__label--corner {
  position: absolute;
  top: 0;
  left: 0;
}
.previewInterest__label--corner svg {
  display: block !important;
}
.previewInterest__label--corner span {
  background: transparent !important;
  position: absolute;
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  padding: 0 !important;
}
.previewInterest__label svg {
  display: none;
  position: absolute;
  width: 12.5rem;
  height: auto;
  transform: scaleX(-1);
  top: 0;
  left: 0;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__label svg {
    width: 8rem;
  }
}
.previewInterest__label span {
  line-height: 1;
  text-transform: uppercase;
  font-family: CI Special;
  font-size: 1rem;
  top: 0;
  left: 0;
  padding: 0.18rem;
  position: absolute;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__label span {
    font-size: 1.05rem;
  }
}
.previewInterest__image {
  flex: 0 0 130px;
  height: 186.55px;
  cursor: pointer;
  text-align: center;
}
.previewInterest__image--hidden {
  visibility: hidden;
  font-size: 0.5rem;
}
.previewInterest__image img {
  height: 100%;
  object-fit: contain;
  max-width: 100%;
}
.previewInterest__image ~ .previewInterest__content {
  flex: 0 1 80%;
}
.previewInterest__message {
  padding: 0.5rem;
  color: #FF0000;
  word-break: break-word;
  flex: 1 0 7.5%;
  font-size: 0.85rem;
  margin-left: auto;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__message {
    font-size: 1rem;
    flex: 1;
    padding: 0 0 0 0.5rem;
  }
}
.previewInterest__added {
  color: #26BC7D;
  background: #FFFFFF;
  border-top: 1px dotted #26BC7D;
  font-size: 0.85rem;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.previewInterest__added svg {
  width: 1rem;
  padding: 0.25rem 0.5rem;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__added {
    margin-top: 1.5rem;
  }
}
.previewInterest__category {
  text-transform: uppercase;
  color: #858585;
  font-size: 0.75rem;
}
.previewInterest__info {
  cursor: pointer;
}
.previewInterest > .title .title__subtitle {
  padding-right: 0 !important;
}
.previewInterest__details {
  font-size: 0.85rem;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__details {
    font-size: 1rem;
  }
}
.previewInterest__details > span:nth-child(even) {
  margin: 0 0.5rem;
}
.previewInterest__quantity-select {
  display: grid;
  align-items: center;
}
.previewInterest__quantity-select .title__main {
  text-align: right;
}
.previewInterest__quantity-select .title__subtitle {
  right: 0;
  left: initial;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__quantity-select {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    justify-content: space-between;
  }
  .previewInterest__quantity-select .title {
    font-size: 1rem;
  }
  .previewInterest__quantity-select .previewInterest__input {
    display: flex;
  }
  .previewInterest__quantity-select .previewInterest__input > .number-input {
    margin: auto;
  }
  .previewInterest__quantity-select .previewInterest__input + .title .title__main {
    text-align: initial;
  }
  .previewInterest__quantity-select .previewInterest__input + .title .title__subtitle {
    right: initial;
  }
}
.previewInterest__notes {
  padding: 1px;
  border: 1px dotted #BBBDBD;
}
.previewInterest__input {
  min-width: 8rem;
  text-align: center;
  margin: 0 4rem;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest__input {
    margin: 0 2rem;
  }
}
.previewInterest--displayMini {
  padding: 0;
  display: initial;
  color: #0C1217;
  font-size: 0.65rem;
}
.previewInterest--displayMini .button--link {
  font-size: 0.6rem;
  padding-bottom: 0;
  padding-top: 0.5rem;
}
.previewInterest__wrapper--displayMini {
  padding: 0.25rem;
  padding-left: 0;
}
.previewInterest__wrapper--displayMini > .previewInterest__image {
  flex: 0 0 70px;
  height: 64px;
  align-self: center;
}
.previewInterest--displayAsTile {
  padding: 0;
  grid-template-columns: 1fr;
  text-align: center;
}
.previewInterest__wrapper--displayAsTile {
  padding: 1rem;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: center;
}
.previewInterest__wrapper--displayAsTile > .previewInterest__message {
  display: none;
}
.previewInterest__wrapper--displayAsTile > .previewInterest__content {
  text-align: center;
}
.previewInterest--displayAsTile > .previewInterest__info > .util {
  justify-content: center;
}
.previewInterest__wrapper--displayAsTile > .previewInterest__content > .previewInterest__added {
  display: block;
  font-size: 0.75rem;
}
.previewInterest__wrapper--displayAsTile > .previewInterest__content > .previewInterest__added svg {
  display: none;
}
.previewInterest__wrapper--displayAsTile > .previewInterest__image {
  flex: 0 0 287px;
  height: 287px;
  padding: 0.5rem;
  align-self: center;
}
.previewInterest--displayAsTile > .previewInterest__quantity-select {
  bottom: 1.5rem;
  right: 1rem;
  left: 1rem;
  justify-content: center;
}
.previewInterest--displayAsTile > .previewInterest__quantity-select .title {
  font-size: 0.85rem;
}
.previewInterest--displayAsTile > .previewInterest__quantity-select .title__subtitle {
  font-size: 0.6rem;
}
.previewInterest--displayAsTile + .previewInterest--discount {
  display: flex;
  font-size: 0.75rem;
}
.previewInterest--displayAsTile + .previewInterest--discount .title {
  text-align: right;
  font-size: 0.75rem;
}
.previewInterest--displayAsTile + .previewInterest--discount .title__subtitle {
  font-size: 0.6rem;
}
.previewInterest--displayAsTile + .previewInterest--discount .previewInterest__input {
  min-width: initial;
}
.previewInterest--disabled {
  background: transparent;
  margin-bottom: 0;
  padding-left: 1rem;
  padding-right: 2rem;
}
.previewInterest--disabled > .button--icon {
  display: none;
}
.previewInterest--disabled .availability-status, .previewInterest--disabled > .button--icon {
  display: none;
}
.previewInterest--disabledFilled {
  margin-bottom: 0.25rem;
}
.previewInterest--discount {
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.85rem;
  position: relative;
}
.previewInterest--discount .previewInterest__info {
  cursor: initial;
  text-align: center;
  align-items: center;
  display: flex;
}
.previewInterest--discount .title {
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest--discount .previewInterest__quantity-select {
    position: initial;
    margin-left: 1rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .previewInterest--discount .previewInterest__input {
    margin: 0 0.5rem;
  }
}
.previewInterest--discount .previewInterest__info {
  padding-left: 0.25rem;
}
.previewInterest--disabled + .previewInterest--discount {
  background: transparent;
  border-top: 1px solid #0C1217;
  margin-top: -1px;
  padding-left: 1rem;
  padding-right: 2rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
}
@media only screen and (max-width: 56.25em) {
  .previewInterest--disabled + .previewInterest--discount {
    border: none;
  }
}
.previewInterest--disabledFilled + .previewInterest--discount {
  background: #FFFFFF;
  border: none;
  margin-top: -0.5rem;
}


