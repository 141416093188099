.order-page__bottom {
  display: flex;
}
.order-page__bottom_left {
  width: 100%;
}
.order-page__notes {
  max-width: 25rem;
}

@media print {
  .order-page {
    background: transparent;
    margin-top: -8rem;
  }
  .order-page__bottom {
    flex-direction: column;
  }
  .order-page__notes {
    max-width: initial;
  }
  .preview {
    padding: 0.5rem 0.5rem 0.75rem 0;
  }
  .preview__info {
    padding: 0 0.5rem 0 0.5rem;
    font-size: 0.75rem;
  }
  .title--h2 {
    font-size: 1.5rem;
  }
  .title--h4 {
    font-size: 1.05rem;
  }
  .title--body {
    font-size: 0.75rem;
  }
  .preview__wrapper + br,
  .preview__details,
  .button-group,
  .button {
    display: none;
    overflow: hidden !important;
    height: 0 !important;
  }
}

