.customer-tab {
  color: black;
}
.customer-tab .table-wrapper {
  display: block;
  overflow: auto;
  margin-bottom: 1rem;
}
.customer-tab table {
  width: 100%;
  border-collapse: separate;
  background: #FFFFFF;
}
.customer-tab tr:nth-child(odd) {
  background-color: #F5F5F5;
}
.customer-tab td {
  border-bottom: 1px solid #414141;
  padding: 0.5rem;
  font-size: 0.75rem;
}
.customer-tab td:first-child {
  border-left: 1px solid #414141;
}
.customer-tab td:last-child {
  border-right: 1px solid #414141;
}
.customer-tab tr:first-child > td {
  border-top: 1px solid #414141;
}
.customer-tab thead td {
  background: #0C1217;
  color: #FFFFFF;
}
.customer-tab tfoot td {
  background: #FFFFFF;
  border-top: none !important;
  font-family: CI Special;
  color: #0C1217;
  font-size: 1.25rem;
}

@page {
  size: auto;
  margin: 2.5mm;
}
@media print {
  .customer-tab-page {
    background: transparent;
    margin-top: -8rem;
  }
  .select-range {
    padding: 0;
  }
  .react-daterange-picker__wrapper {
    border: none;
  }
  .react-daterange-picker__button {
    display: none;
  }
  body,
  td {
    -webkit-print-color-adjust: exact;
    padding: 0 !important;
    font-size: 0.7rem !important;
  }
  td {
    padding: 0 0.25rem !important;
  }
  .button,
  .search-popup,
  .active-order,
  .footer,
  .header {
    display: none;
  }
}


