@import "../../assets/scss/variables";

.login {
  margin-top: -($header-height + 2rem);
  background: $color-5;
  height: 100vh;
  overflow: hidden;


  &__background {
    overflow: hidden;
    width: 100%;
    position: absolute;
    height: 100%;

    .background-top {
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      top: 4%;
      @include responsive($bp-sm) {
        top: 3%;
      }
    }


    @include responsive($bp-sm) {
      overflow: hidden;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__top {
    text-align: right;
    z-index: 2;
    background: $color-3;
    height: 4rem;
    position: relative;

    img {
      position: absolute;
      right: 0;
      top: 0;
      width: 10%;
      min-width: 5rem;


      @include responsive($bp-lg) {
        padding-top: 3%;
      }
      padding-top: 2%;
      padding-right: 2%;
    }

    .language-select {
      position: absolute;
      font-size: 1.5rem;
      font-weight: $font-weight-bold;

      color: $color-1;
      bottom: initial;
      right: initial;
      left: 1rem;
      top: 1rem;
    }

  }

  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - #{$header-height - 1});
    //margin-top: $header-height;
    //top: -$header-height + 1;
    position: relative;
  }

  &__box {
    padding: 0 3rem;
    flex: 1 1 50%;

    .title {
      margin-bottom: .25rem;
    }

    p {
      margin: 1.5rem 0;
      max-width: 40rem;
    }

    &:nth-child(1) {
      color: $color-1;
      flex: 1;
      padding: 0 !important;
      height: 100%;

      @include responsive($bp-lg){
        display: none;
      }
    }

    &:nth-child(2) {
      flex: 0 0 30%;
      @include responsive($bp-lg){
        flex: 1;
      }
      background: $color-2;
      height: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      z-index: 0;
    }

    .button--link:hover,
    .button--link:focus {
      color: $color-4;
    }

    form,
    form input[type="text"],
    form input[type="email"],
    form input[type="password"] {
      width: 100%;

    }

    @include responsive($bp-sm) {
      margin: 0 !important;
      padding: 0 1rem;
      //min-height: 60vh;
      //display: flex;
      //justify-content: center;
      //flex-direction: column;
      font-size: 1.5rem;
      .title:nth-child(2) {
        margin-bottom: 2rem;
      }
      .button {
        //margin-bottom: 2rem;
        margin-top: 2rem;
        padding: 1.5rem 2rem;
        font-size: 1rem;
      }
      form input[type="text"],
      form input[type="password"] {
        font-size: 1.5rem;
        padding: 1.75rem 1rem;
      }
      p {
        margin: initial;
        max-width: initial;
      }
    }
  }

  &__store-select {
    text-align: center;
    display: flex;
    flex-direction: column;
    .button {
      margin: auto
    }
  }

  &__social {
    margin-top: auto;

    > div {
      display: flex;
      align-items: center;

      a {
        margin: 1rem;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .after-1 {
    width: 100%;
    height: .75rem;
    border-bottom: 1px solid $color-3;
    text-align: center;

    span {
      transform: translateY(-50%);
      //font-size: 40px;
      font-weight: bold;
      //font-family: $font-2;
      font-size: .8rem;
      line-height: 1;
      background-color: $color-2;
      padding: 0 .5rem;
    }
  }

  .after-2 {
    font-family: $font-2;
    display: flex;
    justify-content: space-around;

    a:hover {
      color: $color-4;
    }
  }
}

:root {
  --video-width: 100vw;
  --video-height: 100%;
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}