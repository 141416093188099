.login {
  margin-top: -7rem;
  background: #414141;
  height: 100vh;
  overflow: hidden;
}
.login__background {
  overflow: hidden;
  width: 100%;
  position: absolute;
  height: 100%;
}
.login__background .background-top {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  top: 4%;
}
@media only screen and (max-width: 37.5em) {
  .login__background .background-top {
    top: 3%;
  }
}
@media only screen and (max-width: 37.5em) {
  .login__background {
    overflow: hidden;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.login__top {
  text-align: right;
  z-index: 2;
  background: #0C1217;
  height: 4rem;
  position: relative;
}
.login__top img {
  position: absolute;
  right: 0;
  top: 0;
  width: 10%;
  min-width: 5rem;
  padding-top: 2%;
  padding-right: 2%;
}
@media only screen and (max-width: 68.75em) {
  .login__top img {
    padding-top: 3%;
  }
}
.login__top .language-select {
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  color: #FFFFFF;
  bottom: initial;
  right: initial;
  left: 1rem;
  top: 1rem;
}
.login__form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 4rem);
  position: relative;
}
.login__box {
  padding: 0 3rem;
  flex: 1 1 50%;
}
.login__box .title {
  margin-bottom: 0.25rem;
}
.login__box p {
  margin: 1.5rem 0;
  max-width: 40rem;
}
.login__box:nth-child(1) {
  color: #FFFFFF;
  flex: 1;
  padding: 0 !important;
  height: 100%;
}
@media only screen and (max-width: 68.75em) {
  .login__box:nth-child(1) {
    display: none;
  }
}
.login__box:nth-child(2) {
  flex: 0 0 30%;
  background: #FF9F00;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 0;
}
@media only screen and (max-width: 68.75em) {
  .login__box:nth-child(2) {
    flex: 1;
  }
}
.login__box .button--link:hover,
.login__box .button--link:focus {
  color: #633025;
}
.login__box form,
.login__box form input[type=text],
.login__box form input[type=email],
.login__box form input[type=password] {
  width: 100%;
}
@media only screen and (max-width: 37.5em) {
  .login__box {
    margin: 0 !important;
    padding: 0 1rem;
    font-size: 1.5rem;
  }
  .login__box .title:nth-child(2) {
    margin-bottom: 2rem;
  }
  .login__box .button {
    margin-top: 2rem;
    padding: 1.5rem 2rem;
    font-size: 1rem;
  }
  .login__box form input[type=text],
  .login__box form input[type=password] {
    font-size: 1.5rem;
    padding: 1.75rem 1rem;
  }
  .login__box p {
    margin: initial;
    max-width: initial;
  }
}
.login__store-select {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.login__store-select .button {
  margin: auto;
}
.login__social {
  margin-top: auto;
}
.login__social > div {
  display: flex;
  align-items: center;
}
.login__social > div a {
  margin: 1rem;
}
.login__social > div a svg {
  width: 1rem;
  height: 1rem;
}
.login .after-1 {
  width: 100%;
  height: 0.75rem;
  border-bottom: 1px solid #0C1217;
  text-align: center;
}
.login .after-1 span {
  transform: translateY(-50%);
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1;
  background-color: #FF9F00;
  padding: 0 0.5rem;
}
.login .after-2 {
  font-family: CI Special;
  display: flex;
  justify-content: space-around;
}
.login .after-2 a:hover {
  color: #633025;
}

:root {
  --video-width: 100vw;
  --video-height: 100%;
}

.video-background {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.video-background iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: var(--video-width);
  height: var(--video-height);
  transform: translate(-50%, -50%);
}

