@import "../../assets/scss/variables";

$primary: #E6EEF5;
$border-color: $color-3;

.table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: separate;
  border: 1px solid $border-color;

  &--no-bottom {
    padding-bottom: 0;
  }

  tr:last-child td {
    border-bottom: none !important;
  }

  td {
    border: 1px solid $border-color;;
    padding: .5rem 1rem;

    @include responsive($bp-sm) {
      padding: .5rem .8rem;
    }
  }


  & > tbody > tr > td,
  & > tbody > tr > th,
  & > thead > tr > td,
  & > thead > tr > th {
    vertical-align: middle !important;
  }

  tbody th {
    font-size: 2*$base-unit;
    font-weight: bold;
  }

  label {
    font-weight: initial;
  }

  .validation_error {
    position: initial !important;
  }

  &__wrapper {
    display: flex;
    overflow: auto;

    table {
      tr td:first-child {
        width: 0;
        white-space: nowrap;
      }
    }
  }

  &--has-row-action {
    tbody tr {
      cursor: pointer;

      &:hover {
        background: $primary;
      }
    }
  }
}

.pagination {
  margin-top: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  .pagination-inner > * {
    margin-right: $base-unit;
    width: initial;
    font-size: 12px;
  }

  .button {
    color: inherit;
    text-decoration: initial;

    &:last-child {
      margin-right: -.5rem;
    }
  }
}

.expand-toggle {
  padding: $base-unit/2;
  font-family: $font-2;
}