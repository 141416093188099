.dv-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dv-row-2 {
  margin-top: 0.5rem;
}

.dv-row-2 > * {
  width: 100%;
}

.dv-actions {
  display: flex;
}
.dv-actions > div {
  display: flex;
  padding: 2px;
  cursor: pointer;
  margin-left: 0.5rem;
}
.dv-actions > div > svg {
  width: 16px;
  height: 16px;
}

