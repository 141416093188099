.select-range {
  margin-top: 1rem;
  padding: 1rem;
  background: #FFFFFF;
  display: inline-flex;
  align-items: center;
}
.select-range .title {
  margin-right: 2rem;
}
.select-range .button {
  margin-left: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .select-range {
    display: flex;
    flex-direction: column;
  }
  .select-range .title,
  .select-range .react-daterange-picker,
  .select-range .button {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .select-range .title + div {
    width: 100%;
  }
  .select-range .react-daterange-picker__wrapper {
    text-align: center;
  }
}

