.react-daterange-picker {
  display: inline-flex;
  position: relative;
}
.react-daterange-picker--disabled {
  background-color: #FFFFFF;
  color: #0C1217;
}
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: thin solid #414141;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  height: 100%;
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px !important;
  border: 0;
  background: none !important;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button, .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon, .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
  stroke: #FF9F00;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #414141;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.2);
}
.react-daterange-picker__calendar--closed {
  display: none;
}

.react-calendar {
  border: none;
}

.react-calendar__navigation__label,
.react-calendar__tile {
  font-size: 0.75rem;
  padding: 1rem 0;
  font-weight: bold;
}

.react-calendar__tile--now {
  background: #633025;
  color: #FFFFFF;
}

.react-calendar__tile--active {
  background: #FF9F00;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #FF9F00;
}

.react-calendar__month-view__days__day--weekend {
  color: currentColor;
}

.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
}


