@import "../../assets/scss/variables";

.util {
  display: flex;

  &--justify {
    &-space-between {
      justify-content: space-between;
    }

    &-center {
      justify-content: center;
    }

    &-initial {
      justify-content: initial;
    }
  }

  &--direction {
    &-column {
      flex-direction: column;
    }

    &-row {
      flex-direction: row;
    }
  }

  &--align {
    &-center {
      align-items: center;
    }

    &-flex-start {
      align-items: flex-start;
    }

    &-baseline {
      align-items: baseline;
    }
  }

  &--spacedItems{
  }

  &--spacedItems > *:not(:last-child) {
    margin-right: 2.5rem;
  }
}