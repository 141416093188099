@import "variables";
@import "reset";
@import "form";
@import "tooltip";

html {
  box-sizing: border-box;
  font-size: 16px;

  @media only screen and (max-width: $bp-md) {
    font-size: 13.6px;
  }

  @media only screen and (max-width: $bp-sm) {
    font-size: 12.2px;
  }
}

#root ~ iframe {
  display: none;
}

body {
  font-weight: $font-weight-default;
  line-height: 1.6;
  min-height: 100vh;
  font-family: $font-1;
  margin-top: $header-height + 2rem;

  ::selection {
    background: $color-warning;
  }
}

strong {
  font-weight: $font-weight-bold;
}

a:not(.button),
a:not(.button):visited {
  color: $color-3;
  transition: color .1s ease-out;

  &:hover {
    color: $color-2;
  }
}

.container {
  background: $color-background;
}

.bg-container {
  background: $color-background;
  margin-top: -2rem;

  &--transparent {
    background: initial;
  }

  @include responsive($bp-sm) {
    margin-top: 0;
  }

  &.contained-page {
    padding-top: 4rem;
  }
}

.contained-page {
  padding: 2rem 4rem;
  @include responsive($bp-sm) {
    padding: 2rem 1rem;
  }
}


.wrap-when-more-than-2 {
  flex-wrap: wrap;

  > .button {
    width: calc(50% - .75rem) !important;
  }

  > .button:nth-last-child(n + 3),
  > .button:nth-last-child(n + 3) ~ * {
    width: initial !important;
    margin-bottom: .5rem;
  }
}

.wrap-when-more-than-3 {
  flex-wrap: wrap;

  > .button {
    width: initial !important;
    margin-bottom: .5rem;
  }
}

.wrap-when-more-than-2,
.wrap-when-more-than-3 {
  @include responsive($bp-sm) {
    flex-direction: column;
    margin: 2rem 0;
    > .button:nth-last-child(n + 3),
    > .button:nth-last-child(n + 3) ~ *:not(.create-order),
    > .button {
      margin-bottom: .5rem;
      margin-left: 0 !important;
      margin-right: 0 !important;
      width: 80% !important;
      padding: 1.75rem 2.5rem;
      font-size: 1.75rem;
    }
  }
}

.hide-mobile {
  @include responsive($bp-sm) {
    display: none !important;
  }
}

.expand-mobile {
  @include responsive($bp-sm) {
    width: 100%;
  }
}

.show-mobile {
  display: none !important;
  @include responsive($bp-sm) {
    display: block !important;
  }
}

.infinite-scroll-component {
  padding-top: 10rem;
  margin-top: -10rem;
}

@include scrollbar($color-2, $color-3, .5rem);
