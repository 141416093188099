.number-input {
  display: flex;
}
.number-input > button {
  padding: 0.75rem 1rem;
  font-size: 0.75rem;
}
@media only screen and (max-width: 56.25em) {
  .number-input > button {
    padding: 0.75rem 1.05rem;
    font-size: 1.4rem;
  }
}

input[type=number] {
  background: #F5F5F5;
  background: #F5F5F5;
  flex: 0 0 auto;
  padding: 0.5rem;
  text-align: center;
  width: 3rem;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

