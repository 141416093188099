@import "../../assets/scss/variables";

.category-dropdown-filter {

  min-width: 600px;
  @include responsive($bp-lg) {
    min-width: initial;
    flex-direction: column;
    margin: 1rem 0;
  }

  > div {
    width: 100%;
    &:nth-child(2){
      margin-left: .5rem;
    }
    @include responsive($bp-lg) {
      &:nth-child(2){
      margin-left: 0;
        margin-top: .5rem;
      }
    }
  }
}