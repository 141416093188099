@import "../../assets/scss/variables";

.orders-page {
  position: relative;
  overflow: hidden;
  margin-top: -2rem;

  @include responsive($bp-lg) {
    background: $color-8;
    padding: 2rem 1rem;
    //overflow: visible;
  }

  .background {
    position: absolute;
    object-fit: cover;
    top: -2rem;
    left: 0;
    bottom: 0;
    right: 0;
    height: 120%;
    width: 55%;

    @include responsive($bp-lg) {
      left: initial;
      bottom: initial;
      right: initial;
      object-fit: initial;
      //transform: rotate(270deg) translateX(-100%);
      transform: rotate(270deg);
      width: 100%;
      height: 109vw;
      top: -3rem;
      background: $color-8;
    }
  }

  .background + div {
    position: relative;
    @include responsive($bp-lg) {
      padding-top: 2rem;
    }
  }


  .wrapper-1 {
    @include responsive($bp-lg) {
      margin-left: -1rem;
      margin-right: -1rem;
      position: relative;
      overflow: hidden;
      background: $color-2;
      margin-top: -.5%;
      margin-bottom: -2.5%;
      .background.show-mobile + div {
        padding: 2rem 1rem;
        padding-top: 25%;
      }
    }
  }

  &__main {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
            "a a b b b b";
    grid-gap: 2rem;

    & > *:nth-child(1) {
      grid-area: a
    }

    & > *:nth-child(2) {
      grid-area: b
    }

    @include responsive($bp-lg) {
      grid-template-areas: "b b b b b b" "a a a a a a";
    }
  }

  @include responsive($bp-lg) {
    table {
      border: none;
    }
  }

  tr {
    background: $color-1;

    @include responsive($bp-md) {
      display: grid;
      margin-bottom: 1rem;
      grid-template-columns: repeat(4, auto);

      border: 1px solid $color-3;
      //border-bottom: none;

      & > td {
        display: flex;
        border: none;
        justify-content: center;

        &:nth-child(5) {
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &:nth-child(6) {
          grid-column-start: 3;
          grid-column-end: 5;
          border-left: 1px solid $color-3;
        }

        &:nth-child(5),
        &:nth-child(6) {
          border-top: 1px solid $color-3;
          align-items: center;
          margin-bottom: -1px;
        }
      }
    }
  }

  .current-order {
    background: $color-warning;

    a {
      color: $color-3 !important
    }
  }

  .submit-order {
    background:  #e6e6e6;
  }

}