@import "../../assets/scss/variables";

.order-status {
  font-size: .85rem;
  font-weight: bold;

  &--0 {
    color: $color-3;
    text-decoration: underline;
  }

  &--1 {
    color: $color-success;
  }

  &--2 {
    color: $color-7;
  }

}