@import "../../assets/scss/variables";

.make-favorite {
  @media print {
    display: none;
  }
  line-height: initial;
  background: transparent;
  border: none;
  padding: 0.5rem .5rem;
  font-family: $font-2;
  font-size: 1.15rem;
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  span {
    line-height: 1;

    &:nth-child(2) {
      margin-left: .5rem;
    }
  }

  color: $color-7;
  opacity: .6;

  &:hover {
    opacity: 1;
  }

  &--true {
    color: $color-3;
    opacity: 1;

    span:nth-child(2) {
      color: $color-error;
    }
  }
}

.preview {
  padding: 1rem 1rem 1rem 0;
  background: $color-1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: .5rem;
  text-align: left;

  @include responsive($bp-md) {
    padding: 0;
    display: initial;
  }

  &__wrapper {
    //overflow: hidden;
    display: flex;
    background: $color-1;
    margin-bottom: .5rem;
    align-items: center;
    position: relative;
    text-align: left;

    &--disabled {
      border: 1px solid $color-3;
      background: transparent;
    }

    &--disabledFilled {
      background: $color-1;
    }

    @include responsive($bp-md) {
      padding: 1rem;
      padding-bottom: 5rem;
      flex-direction: column;
      margin-bottom: 2rem;
      font-size: 1.25rem;
      flex-wrap: wrap;
    }
  }

  &__content {
    flex: 0 1 100%;
  }

  &__label {
    @media print {
      display: none;
    }
    @mixin corner {
      position: absolute;
      top: 0;
      left: 0;

      svg {
        display: block !important;
      }
      span {
        background: transparent !important;
        position: absolute;
        margin-left: .25rem;
        margin-top: .5rem;
        padding: 0 !important;

      }
    }

    @include responsive($bp-md) {
      @include corner
    }

    &--corner {
      @include corner
    }


    svg {
      display: none;
      position: absolute;
      width: 12.5rem;
      height: auto;
      transform: scaleX(-1);
      top: 0;
      left: 0;

      @include responsive($bp-md) {
        width: 8rem;
      }
    }

    span {
      //padding: 0 .25rem;
      line-height: 1;
      text-transform: uppercase;
      font-family: $font-2;
      font-size: 1rem;

      top: 0;
      left: 0;
      padding: 0.18rem;
      position: absolute;

      @include responsive($bp-md) {
        font-size: 1.05rem;
      }
      //color: $color-1;
    }

  }

  &__image {
    flex: 0 0 130px;
    height: 186.55px;
    cursor: pointer;
    text-align: center;

    &--hidden {
      visibility: hidden;
      font-size: .5rem;
    }

    img {
      height: 100%;
      object-fit: contain;
      max-width: 100%;
    }
  }

  &__image ~ &__content {
    flex: 0 1 80%;
  }

  &__message {
    padding: .5rem;
    color: $color-error;
    word-break: break-word;
    //flex: 0 0 7.5%;
    flex: 1 0 7.5%;
    font-size: .85rem;
    margin-left: auto;

    @include responsive($bp-md) {
      font-size: 1rem;
      flex: 1;
      padding: 0 0 0 .5rem;
    }
  }

  &__added {
    color: $color-success;
    background: $color-1;
    border-top: 1px dotted $color-success;
    font-size: .85rem;
    margin-left: auto;
    display: flex;
    align-items: center;

    svg {
      width: 1rem;
      padding: .25rem .5rem;
    }
    @include responsive($bp-md){
      margin-top: 1.5rem;
    }
  }

  &__category {
    text-transform: uppercase;
    color: $color-6;
    font-size: .75rem;
  }

  &__info {
    cursor: pointer;
  }

  & > .title .title__subtitle {
    padding-right: 0 !important;
  }

  &__details {
    font-size: .85rem;
    @include responsive($bp-md) {
      font-size: 1rem;
    }

    & > span:nth-child(even) {
      margin: 0 .5rem
    }
  }

  &__quantity-select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    .title__main {
      text-align: right;
    }

    .title__subtitle {
      right: 0;
      left: initial;
    }

    @include responsive($bp-md) {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      left: 1rem;
      justify-content: space-between;
      .title {
        font-size: 1rem;
      }

      .preview__input {
        display: flex;
      }

      .preview__input > .number-input {
        margin: auto;
      }

      .preview__input + .title .title__main {
        text-align: initial;
      }

      .preview__input + .title .title__subtitle {
        right: initial;
      }
    }
  }

  &__input {
    min-width: 8rem;
    text-align: center;
    margin: 0 4rem;
    @include responsive($bp-md) {
      margin: 0 2rem;
    }
  }

  //----------------------------------------------------------

  &--displayMini {
    padding: 0;
    display: initial;
    //text-align: center;
    color: $color-3;
    font-size: .65rem;

    .button--link {
      font-size: .6rem;
      padding-bottom: 0;
      padding-top: .5rem;
    }
  }

  &__wrapper--displayMini {
    padding: .25rem;
    padding-left: 0;
  }

  &__wrapper--displayMini > &__image {
    flex: 0 0 70px;
    height: 64px;
    //padding: .5rem;
    align-self: center
  }

  //----------------------------------------------------------

  &--displayAsTile {
    padding: 0;
    display: initial;
    text-align: center;
  }

  &__wrapper--displayAsTile {
    padding: 1rem;
    padding-bottom: 5rem;
    flex-direction: column;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: center;
  }

  &__wrapper--displayAsTile > &__message {
    display: none;
  }

  &__wrapper--displayAsTile > &__content {
    text-align: center;
    //width: 100%;
  }

  &--displayAsTile > &__info > .util {
    justify-content: center;
  }

  &__wrapper--displayAsTile > &__content > &__added {
    display: block;
    font-size: .75rem;

    svg {
      display: none;
    }
  }

  &__wrapper--displayAsTile > &__image {
    flex: 0 0 287px;
    height: 287px;
    padding: .5rem;
    align-self: center
  }

  &--displayAsTile > &__quantity-select {
    position: absolute;
    bottom: 1.5rem;
    right: 1rem;
    left: 1rem;
    justify-content: space-between;

    .title {
      font-size: .85rem;
    }

    .title__subtitle {
      font-size: .6rem;
    }
  }


  &--displayAsTile + &--discount {
    display: flex;
    font-size: .75rem;

    .title {
      text-align: right;
      font-size: .75rem;
    }

    .title__subtitle {
      font-size: .6rem;
    }

    .preview__input {
      min-width: initial;
    }
  }

  //----------------------------------------------------------

  &--disabled {
    background: transparent;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 2rem;
  }

  &--disabled > .button--icon {
    display: none;
  }

  &--disabled .availability-status,
  &--disabled > .button--icon {
    display: none;
  }

  &--disabledFilled {
    margin-bottom: .25rem;
  }

  //----------------------------------------------------------

  &--discount {
    margin-top: -.5rem;
    margin-bottom: .5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: .85rem;
    position: relative;

    .preview__info {
      cursor: initial;
      text-align: center;
      align-items: center;
      display: flex;
    }

    .title {
      margin-bottom: 0.5rem;
    }
  }

  &--discount &__quantity-select {
    @include responsive($bp-md) {
      position: initial;
      margin-left: 1rem;
    }
  }

  &--discount &__input {
    @include responsive($bp-md) {
      margin: 0 .5rem;
    }
  }

  &--discount &__info {
    padding-left: 0.25rem;
  }

  &--disabled + &--discount {
    background: transparent;
    border-top: 1px solid $color-3;
    margin-top: -1px;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: .5rem;

      margin-bottom: 0;
    @include responsive($bp-md) {
      border: none;
    //border-bottom: 1px solid $color-3;
    }
  }

  &--disabledFilled + &--discount {
    background: $color-1;
    border: none;
    margin-top: -.5rem;
  }
}