.order-create-page__bottom {
  display: flex;
}
.order-create-page__bottom_left {
  width: 100%;
}
.order-create-page__bottom_right {
  min-width: 25rem;
}
.order-create-page__bottom_right .button {
  margin-left: auto;
}
.order-create-page__notes {
  max-width: 25rem;
}
.order-create-page__notes .title {
  margin-bottom: 0.5rem;
}

