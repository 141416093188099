@import "../../assets/scss/variables";

.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: $z-index-header;
  height: $header-height;
  position: fixed;
  overflow: hidden;
  padding: 0 1rem;
  background: $color-3;
  color: $color-1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--mobile {
    display: none;
  }

  @include responsive($bp-sm) {
    display: none;
    &--mobile {
      display: flex;
      height: $header-height + 2rem;
    }
  }

  &--mobile &__left {
    position: relative;
    flex: 1;
    align-self: stretch;
    padding: 0;

    > img {
      position: absolute;
      left: -1rem;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 90%;
      object-fit: cover;
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      color: $color-3 !important;

      > * {
        margin-right: 2rem;
        color: inherit;
      }
    }
  }

  &--mobile &__right img {
    right: 1rem
  }

  &__info {
    font-size: .65rem;
    font-weight: $font-weight-bold;
    margin-bottom: .5rem;
    display: flex;

    & > * {
      margin-right: 0.25rem;
      max-width: 140px;
    }

    select {
      background: transparent;
      color: $color-1;
      border: none;
      padding: 0;
      margin-left: .5rem;

      option {
        color: $color-3;
      }
    }
  }

  &__left {
    .button {
      padding: 0;

      &:hover, &:focus {
        background: transparent;
      }
    }
  }

  &__middle {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    z-index: 1;

    a,
    button {
      cursor: pointer !important;
      background: transparent;
      border: none;
      font-size: 1rem;
      line-height: 1;

      display: inline-flex;
      align-items: center;
      color: $color-1 !important;
      text-decoration: none;
      font-family: $font-2;

      span {
        @include hover-underline($color-1)
      }

      &:hover {
        color: $color-1;
      }

      .badge {
        margin-top: -2px;
      }
    }
  }

  &__right {
    color: $color-3;
    display: flex;

    img {
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    & > div {
      margin-left: auto;
      position: relative;
    }

    .button {
      padding: 0;
      color: inherit;
    }

    a:hover {
      color: $color-3 !important;
    }
  }

  &__logo {
    width: 5rem;
    height: auto;
  }

  &__left,
  &__right {
    flex: 0 1 25%;
  }

  @include responsive($bp-md) {
    &__left,
    &__right {
      flex: 0 1 20%;
    }
  }

}