@import "../../assets/scss/variables";

.footer {
  background: $color-1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem;
  position: relative;

  &__left {
    position: relative;

    a {
      margin-right: 2rem;
    }
  }

  &__right {
    position: relative;
    text-align: right;

    button, a {
      border: none;
      background: transparent;
      margin-left: 1rem;
    }
  }


  p {
    font-size: .75rem;
  }

  > img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    top: -2rem;

  }
}