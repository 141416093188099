.ft-range {
  display: flex;
  align-items: center;

  input:first-child {
    margin-right: .5rem;
  }

  input {
    min-width: 10rem;
    padding: .25rem;

  }
}