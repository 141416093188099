@import "../../../assets/scss/variables";

$border-color: $color-5;

.report2-page{

  .table-wrapper {
    display: block;
    overflow: auto;
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    border-collapse: separate;
    background: $color-1;
  }

  tr:nth-child(odd) {
    background-color: $color-8;
  }
  th{
    border-left: 1px solid $border-color;
  }

  th,
  td {
    border-bottom: 1px solid $border-color;
    padding: .5rem;
    font-size: .75rem;

    &:first-child {
      border-left: 1px solid $border-color;
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }

  tr:first-child > th,
  tr:first-child > td {
    border-top: 1px solid $border-color;
  }

  thead th,
  thead td {
    background: $color-3;
    color: $color-1;
  }

  tfoot tr {
  }

  tfoot td {
    background: $color-1;
    border-top: none !important;
    font-family: $font-2;
    color: $color-3;
    font-size: 1.25rem;
  }
}


