@import "../../assets/scss/variables";

.list {
  max-height: 600px;
  overflow: auto;

  &__item:first-child {
    border-top: none;
  }

  &--fullHeight {
    max-height: initial;
  }

  &--dense .list__item{
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}