.category-dropdown-filter {
  min-width: 600px;
}
@media only screen and (max-width: 68.75em) {
  .category-dropdown-filter {
    min-width: initial;
    flex-direction: column;
    margin: 1rem 0;
  }
}
.category-dropdown-filter > div {
  width: 100%;
}
.category-dropdown-filter > div:nth-child(2) {
  margin-left: 0.5rem;
}
@media only screen and (max-width: 68.75em) {
  .category-dropdown-filter > div:nth-child(2) {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}

