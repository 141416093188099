@import "../../../../assets/scss/variables";

.list__item {
  display: block;
  padding: 1rem 0 1rem 0;
  border-top: 2px solid $color-3;
  overflow: hidden;
  position: relative;

  &--contained {
    margin-left: 4rem;
    margin-right: 4rem;

    @include responsive($bp-sm) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &--no-borders {
    border-top: none !important;;
    border-bottom: none !important;
  }

  &--no-borders + & {
    border-top: none !important;
  }

  img {
    max-width: 100%;
  }
}