@import "../../assets/scss/variables";

.card {
  position: relative;
  background: $color-2;
  padding: 1.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  img {
    max-width: 60%;
    height: auto;
  }

  &--light {
    color: $color-1;
  }

  &__corner--1 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  &__corner--2 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }

  &__content {
    z-index: 2;
    position: relative;
  }

  &__description {
    font-size: .85rem;
    margin-bottom: 1rem;

    @include responsive($bp-sm) {
      font-size: 1.1rem;
    }

  }

  &__action {
    text-align: right;
  }

  &__pre-text {
    @include sup;
    font-size: .8rem;
    margin-left: 0;
  }

}