.order-page .info .util > span {
  margin-left: 0.5rem;
}
.order-page__bottom {
  display: flex;
}
.order-page__bottom_left {
  width: 100%;
}
.order-page__notes {
  max-width: 25rem;
}
@media only screen and (max-width: 56.25em) {
  .order-page__bottom_right {
    width: 100%;
  }
}

