.display-tiles .infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
@media only screen and (max-width: 56.25em) {
  .display-tiles .infinite-scroll-component {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 37.5em) {
  .display-tiles .infinite-scroll-component {
    grid-template-columns: repeat(1, 1fr);
  }
}
.display-tiles .infinite-scroll-component > div {
  display: flex;
}
.display-tiles .infinite-scroll-component > div > .preview__wrapper {
  display: initial;
  width: 100%;
  margin-bottom: 0;
  font-size: 0.85rem;
}
.display-tiles .infinite-scroll-component .preview__input {
  margin: 0 1rem;
}
.display-tiles .infinite-scroll-component .preview--discount {
  margin-top: 0;
}

