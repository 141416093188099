.util {
  display: flex;
}
.util--justify-space-between {
  justify-content: space-between;
}
.util--justify-center {
  justify-content: center;
}
.util--justify-initial {
  justify-content: initial;
}
.util--direction-column {
  flex-direction: column;
}
.util--direction-row {
  flex-direction: row;
}
.util--align-center {
  align-items: center;
}
.util--align-flex-start {
  align-items: flex-start;
}
.util--align-baseline {
  align-items: baseline;
}
.util--spacedItems > *:not(:last-child) {
  margin-right: 2.5rem;
}

