@import "../../assets/scss/variables";

.active-order {
  background: $color-1;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.2);

  padding: .75rem 2rem;
  max-width: 75%;

  margin-left: auto;
  margin-right: auto;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include responsive($bp-sm) {
      flex-direction: column;
    }
  }



  &__separator {
    align-self: stretch;
    width: 2px;
    background: $color-3;
    margin: 0 1.5rem;
    padding: -1rem 0;
    @include responsive($bp-sm) {
      width: 100%;
      height: 2px;
      margin: 0;
    }
  }

  &__item {
    padding: .5rem 0;
    width: 100%;

    &:last-child {
      padding: 0
    }
  }

  .button {
    box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
  }
}