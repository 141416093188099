.report2-page .table-wrapper {
  display: block;
  overflow: auto;
  margin-bottom: 1rem;
}
.report2-page table {
  width: 100%;
  border-collapse: separate;
  background: #FFFFFF;
}
.report2-page tr:nth-child(odd) {
  background-color: #F5F5F5;
}
.report2-page th {
  border-left: 1px solid #414141;
}
.report2-page th,
.report2-page td {
  border-bottom: 1px solid #414141;
  padding: 0.5rem;
  font-size: 0.75rem;
}
.report2-page th:first-child,
.report2-page td:first-child {
  border-left: 1px solid #414141;
}
.report2-page th:last-child,
.report2-page td:last-child {
  border-right: 1px solid #414141;
}
.report2-page tr:first-child > th,
.report2-page tr:first-child > td {
  border-top: 1px solid #414141;
}
.report2-page thead th,
.report2-page thead td {
  background: #0C1217;
  color: #FFFFFF;
}
.report2-page tfoot td {
  background: #FFFFFF;
  border-top: none !important;
  font-family: CI Special;
  color: #0C1217;
  font-size: 1.25rem;
}


