@import "../../../assets/scss/variables";

$border-color: $color-5;

.report1-page {

  .kpi {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: $color-3;
    color: $color-1;
    margin: 4px 0;

    div {
      min-width: 260px;
      text-align: center;
    }

    div:first-child {
      color: $color-2
    }

  }

  @media (screen and max-width: $bp-sm) {
    .kpi,
    .kpi div {
      width: 100%;
    }
  }

  .table-wrapper {
    display: block;
    overflow: auto;
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    border-collapse: separate;
    background: $color-1;
  }

  tr:nth-child(odd) {
    background-color: $color-8;
  }

  th {
    border-left: 1px solid $border-color;
  }

  th,
  td {
    border-bottom: 1px solid $border-color;
    padding: .5rem;
    font-size: .75rem;

    &:first-child {
      border-left: 1px solid $border-color;
    }

    &:last-child {
      border-right: 1px solid $border-color;
    }
  }

  tr:first-child > th,
  tr:first-child > td {
    border-top: 1px solid $border-color;
  }

  thead th,
  thead td {
    background: $color-3;
    color: $color-1;
  }

  tfoot tr {
  }

  tfoot td {
    background: $color-1;
    border-top: none !important;
    font-family: $font-2;
    color: $color-3;
    font-size: 1.25rem;
  }

  &-toolbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}
