@import "../../../../assets/scss/variables";

.number-input {
  display: flex;
  > button {
    padding: .75rem 1rem;
    font-size: .75rem;
    //background: $color-3;
    //color: $color-1;
    //cursor: pointer;

    @include responsive($bp-md) {
      padding: 0.75rem 1.05rem;
      font-size: 1.4rem;
    }
  }
}

input[type="number"] {
  background: $color-8;
  background: #F5F5F5;
  flex: 0 0 auto;
  //padding: 0.5rem 0 0.5rem 1rem;
  padding: 0.5rem ;
  text-align: center;
  width: 3rem;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}