@import "../../assets/scss/variables";

.existing-order-modal {
  text-align: center;

  .title {
    justify-content: center;
  }

  .button-group {
    justify-content: center;

    @include responsive($bp-sm) {
      flex-direction: column;

      > .button {
        margin: 0;
        margin-bottom: .5rem;
        width: 100%;
        display: block;
      }
    }
  }
}