.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(12, 18, 23, 0.6);
  overflow: auto;
}
.modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  overflow: auto;
  background: #FFFFFF;
  transition: min-width 0.2s ease-out;
  display: flex;
}
.modal__container {
  min-width: 12.5rem;
  position: relative;
  padding: 10% 4rem 12.5% 4rem;
}
@media only screen and (max-width: 37.5em) {
  .modal__container {
    max-width: 85vw;
  }
}
.modal__container > div {
  position: relative;
  z-index: 1;
}
.modal__container > .button {
  position: absolute !important;
  z-index: 2;
  right: 0;
  top: 0;
  padding: 0 0.5rem;
  color: #FFFFFF;
}
@media only screen and (max-width: 37.5em) {
  .modal__container > .button {
    top: -0.5rem;
  }
}
.modal__container > .button svg {
  width: 1rem;
}
.modal__container > img:nth-child(1) {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 12rem;
}
.modal__container > img:nth-child(2) {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}


