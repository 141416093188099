.reports-page__top {
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  padding-bottom: 4rem;
}
@media only screen and (max-width: 56.25em) {
  .reports-page__top {
    background: transparent;
  }
}
.reports-page__top > img {
  position: absolute;
  left: 0;
  object-fit: cover;
  bottom: 0;
  height: 100%;
  width: 55vw;
}
@media only screen and (max-width: 56.25em) {
  .reports-page__top > img {
    width: 50vw;
  }
}
@media only screen and (max-width: 37.5em) {
  .reports-page__top > img {
    display: none;
  }
}
.reports-page__top > div {
  position: relative;
}
.reports-page__bottom {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  margin-top: -4rem;
}
.reports-page__bottom > img {
  top: 1vh;
  right: 0;
  width: 100%;
  position: absolute;
}
@media (max-width: 68.75em) {
  .reports-page__bottom > img {
    top: 2rem;
  }
}
@media (max-width: 37.5em) {
  .reports-page__bottom > img {
    display: none;
  }
}
.reports-page__bottom > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .reports-page__bottom > div {
    display: block;
  }
}
.reports-page__main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "a a a b b b";
  grid-gap: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .reports-page__main {
    display: block;
  }
}
.reports-page__main > *:nth-child(1) {
  grid-area: a;
}
@media only screen and (max-width: 37.5em) {
  .reports-page__main > *:nth-child(1) {
    max-width: initial;
    margin-bottom: 2rem;
  }
}
.reports-page__main > *:nth-child(1) a:hover {
  color: #633025;
}
.reports-page__main > *:nth-child(2) {
  grid-area: b;
}


