@import "../../assets/scss/variables";

.announcements-page .active-announcement .list__item-text td {
  border: none;
  padding: 0;
}

.styles_scroll-to-top__2A70v:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.announcements-page {
  @include responsive($bp-sm) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .pagination,
  .table__meta,
  &__top {
    padding: 0 4rem;

    @include responsive($bp-sm) {
      padding: 0 1rem;
    }
  }

  .container {
    padding: 2rem 0;
    @include responsive($bp-sm) {
      padding: 1rem 0;
    }
  }

  &__before {
    position: relative;
    margin-top: -2rem;
    background: $color-3;
    height: 6rem;
    overflow: hidden;

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      transform: translateY(60%)
    }

  }

  &__top {
    position: relative;
    padding-bottom: 1rem;

    > div {
      position: relative;
    }

    > img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      transform: translateY(30%)
    }
  }

  &__legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .title {
      margin-right: .5rem;
      margin-left: 1rem;
      font-size: .8rem
    }
  }

  .active-announcement {
    background: $color-1;
    margin-left: 0;
    margin-right: 0;
    padding-left: 4rem;
    padding-right: 4rem;

    @include responsive($bp-sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    table {
      border-collapse: separate;
      overflow: auto;
    }

    td {
      border: 1px solid $color-7;
      padding: 1rem;
    }
  }

  .list-fab {
    margin-left: 5rem;
    padding-right: 1rem;

    @include responsive($bp-sm) {
      margin-left: 1rem;
      padding-right: 0;
    }
  }
}

.item-filter {
  cursor: pointer;
  display: flex;
  align-items: center;

  &--active {
    text-decoration: underline;
  }
}