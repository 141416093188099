@import "../../../../assets/scss/variables";
$size: 16px;

.checkbox {
  display: flex;
  align-items: center;

  label{
    font-size: .75rem;
    cursor: pointer;
  }

  @include responsive($bp-sm) {
    label {
      font-size: 1.1rem;
    }
  }

  @include responsive($bp-sm) {
    label {
      font-size: 1.2rem;
    }
  }
}