.active-order {
  background: #FFFFFF;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.2);
  padding: 0.75rem 2rem;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.active-order > div:first-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 37.5em) {
  .active-order > div:first-child {
    flex-direction: column;
  }
}
.active-order__separator {
  align-self: stretch;
  width: 2px;
  background: #0C1217;
  margin: 0 1.5rem;
  padding: -1rem 0;
}
@media only screen and (max-width: 37.5em) {
  .active-order__separator {
    width: 100%;
    height: 2px;
    margin: 0;
  }
}
.active-order__item {
  padding: 0.5rem 0;
  width: 100%;
}
.active-order__item:last-child {
  padding: 0;
}
.active-order .button {
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.2);
}


