.error-message {
  display: block;
  color: #FF0000;
  font-size: 0.75rem;
}
.error-message strong {
  margin-right: 0.5rem;
}
@media only screen and (max-width: 37.5em) {
  .error-message {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .error-message {
    font-size: 1.1rem;
  }
}

