@import "../../assets/scss/variables";

.order-create-page {

  &__bottom {
    display: flex;
  }

  &__bottom_left {
    width: 100%;

  }

  &__bottom_right {
    min-width: 25rem;

    .button {
      margin-left: auto
    }

    .checkbox {
      //margin-right: 2rem;
    }
  }

  &__notes {
    max-width: 25rem;

    .title {
      margin-bottom: .5rem;
    }
  }
}