.select-period {
  margin-top: 1rem;
  padding: 1rem;
  background: #FFFFFF;
  display: inline-flex;
  align-items: center;
}
.select-period > div {
  margin-right: 2rem;
}
@media only screen and (max-width: 37.5em) {
  .select-period {
    display: flex;
    flex-direction: column;
  }
  .select-period > div {
    margin-right: 0;
  }
  .select-period .title,
  .select-period .react-daterange-picker,
  .select-period .button {
    display: block;
    width: 100%;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .select-period .title + div {
    width: 100%;
  }
  .select-period .react-daterange-picker__wrapper {
    text-align: center;
  }
}

