.announcements-page .active-announcement .list__item-text td {
  border: none;
  padding: 0;
}

.styles_scroll-to-top__2A70v:active {
  transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

@media only screen and (max-width: 37.5em) {
  .announcements-page {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
.announcements-page .pagination,
.announcements-page .table__meta, .announcements-page__top {
  padding: 0 4rem;
}
@media only screen and (max-width: 37.5em) {
  .announcements-page .pagination,
  .announcements-page .table__meta, .announcements-page__top {
    padding: 0 1rem;
  }
}
.announcements-page .container {
  padding: 2rem 0;
}
@media only screen and (max-width: 37.5em) {
  .announcements-page .container {
    padding: 1rem 0;
  }
}
.announcements-page__before {
  position: relative;
  margin-top: -2rem;
  background: #0C1217;
  height: 6rem;
  overflow: hidden;
}
.announcements-page__before img {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(60%);
}
.announcements-page__top {
  position: relative;
  padding-bottom: 1rem;
}
.announcements-page__top > div {
  position: relative;
}
.announcements-page__top > img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transform: translateY(30%);
}
.announcements-page__legend {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.announcements-page__legend .title {
  margin-right: 0.5rem;
  margin-left: 1rem;
  font-size: 0.8rem;
}
.announcements-page .active-announcement {
  background: #FFFFFF;
  margin-left: 0;
  margin-right: 0;
  padding-left: 4rem;
  padding-right: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .announcements-page .active-announcement {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.announcements-page .active-announcement table {
  border-collapse: separate;
  overflow: auto;
}
.announcements-page .active-announcement td {
  border: 1px solid #BBBDBD;
  padding: 1rem;
}
.announcements-page .list-fab {
  margin-left: 5rem;
  padding-right: 1rem;
}
@media only screen and (max-width: 37.5em) {
  .announcements-page .list-fab {
    margin-left: 1rem;
    padding-right: 0;
  }
}

.item-filter {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.item-filter--active {
  text-decoration: underline;
}


