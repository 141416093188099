@import "../../../assets/scss/variables";

.formGroup {
  position: relative;
  margin-bottom: 1rem;

  &__label {
    //visibility: hidden;
    transition: 0.2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1rem;
    font-size: 1rem;
    cursor: text;
    width: 100%;
  }

  & > *:nth-child(1) {

    &::placeholder {
      color: transparent;
    }

    //&:focus ~ .formGroup__label {
    //  top: 0;
    //}

    &:not(:placeholder-shown) ~ .formGroup__label {
      top: -.25rem;
      font-size: .75rem;
      transform: translateY(0)
      //visibility: hidden;
    }
  }


}


