@import "../../assets/scss/variables";

.filter-wrapper {
  @include responsive($bp-lg) {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
  }
}

.product-category {

  .active-order {
    margin-bottom: 2rem;
  }

  .product-category-order {
    padding: 2rem 0;
    background: $color-4;
    position: relative;
    overflow: hidden;
  }

  .product-category-order-image {
    position: absolute;
    z-index: 0;

    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transform: translateY(40%);

    & + div {
      position: relative;
    }
  }

  .product-category-title {
    position: sticky;
    z-index: 5;
    padding-top: .5rem;
    background: $color-8;
    top: $header-height;
    @include responsive($bp-sm) {
      top: $header-height + 2rem;
    }
  }
}

.item-hidden {
  display: none;
}

.item-visible {
  display: flex;
}

[data-scrollspy-id] {
  display: none;

  &.active {
    display: flex;
  }
}
