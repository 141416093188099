.quickView {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(12, 18, 23, 0.6);
  overflow: auto;
}
.quickView__content {
  position: absolute;
  left: 0;
  height: 100%;
  width: auto;
  overflow: auto;
  background: #F5F5F5;
  transition: min-width 0.2s ease-out;
  display: flex;
}
.quickView__container {
  position: relative;
  min-width: 12.5rem;
  padding: 4rem 4rem;
  max-width: 60vw;
}
@media only screen and (max-width: 68.75em) {
  .quickView__container {
    max-width: 80vw;
  }
}
@media only screen and (max-width: 37.5em) {
  .quickView__container {
    max-width: 100vw;
  }
}
.quickView__corner {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.quickView__corner .button {
  position: relative;
  z-index: 2;
  color: #FFFFFF;
}
.quickView__corner .button svg {
  width: 1.5rem;
}
.quickView__corner img {
  z-index: 1;
  position: absolute;
  right: 0;
  width: 12rem;
}


