.accordion {
  margin-bottom: 1rem;
}
.accordion__summary {
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.accordion__summary--no-action {
  cursor: initial;
}
.accordion__details {
  width: 100%;
  height: auto;
}
.accordion--active .accordion__details {
  display: inline-block;
}
.accordion--inactive .accordion__details {
  display: none;
}


