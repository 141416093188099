.create-template-modal input {
  padding: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #0C1217;
  width: 100%;
  border-radius: 0;
}
.create-template-modal .success-message {
  padding: 0.5rem 1rem;
  border: 1px solid #26BC7D;
  margin: 0.5rem 0;
  color: #26BC7D;
  font-weight: bold;
}
.create-template-modal .success-message a {
  font-weight: initial;
  margin: 0 0.5rem;
}


