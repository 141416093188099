@import "../../assets/scss/variables";

.availability-status {
  font-size: .75rem;

  &--0 {
    color: $color-success;

  }

  &--1 {
    color: $color-warning;
  }

  &--2 {
    color: $color-error;
  }

}