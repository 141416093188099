.existing-order-modal {
  text-align: center;
}
.existing-order-modal .title {
  justify-content: center;
}
.existing-order-modal .button-group {
  justify-content: center;
}
@media only screen and (max-width: 37.5em) {
  .existing-order-modal .button-group {
    flex-direction: column;
  }
  .existing-order-modal .button-group > .button {
    margin: 0;
    margin-bottom: 0.5rem;
    width: 100%;
    display: block;
  }
}

