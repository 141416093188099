@import "../../assets/scss/variables";

$ig-blue: $color-2;
$ig-black: $color-3;
$ig-shadow: none;
@import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

.product {
  min-height: 97%;


  &__details {
    $diff: 4rem;
    position: relative;
    margin-left: -$diff;
    margin-right: -$diff;
    padding-top: $diff/2;
    padding-bottom: $diff/2;
    overflow: hidden;


    //&::before {
    //  content: '';
    //  background: $color-1;
    //  position: absolute;
    //  width: 100%;
    //  height: 80%;
    //  left: 0;
    //  right: 0;
    //  bottom: 0;
    //  top: 2.5*$diff;
    //}

    > img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;

      &:nth-child(1) {
        top: 2*$diff;
      }

      &:nth-child(2) {
        bottom: 0;
        transform: translateY(55%)
      }
    }

    > div {
      padding: 0 $diff;
      position: relative;

      .title {
        grid-area: a;
      }
    }


    &--no-background {
      > img,
      &::before {
        display: none;
      }
    }
  }

  &__top {
    grid-area: b;
    font-size: .85rem;
    margin-top: .5rem;

    span:nth-child(even) {
      margin: 0 .5rem;
    }
  }

  &__similar {
    > .title {
      text-align: left;
      margin-bottom: .5rem;
      font-size: 2.5rem;
    }
  }

  &__middle {
    position: relative;

    > img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
    }

    > div {
      position: relative;
    }
  }

  .image-gallery {
    grid-area: c;
  }

  &__description {
    grid-area: e;
  }

}


.add-to-card {
  text-align: center;
  top: 0;
  background: $color-1;
  box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.25);
  z-index: 100;
  padding: .5rem;
  position: sticky;

  .preview__wrapper {
    margin-top: 1rem;
    padding-bottom: 4rem;
    margin-bottom: 0;
    min-height: initial;
  }

  .preview__info,
  .preview__message,
  .preview__image {
    display: none;
  }

  .preview--discount{
    //position: absolute;
    justify-content: center;
  }
  .preview--discount .preview__info {
    position: absolute;
    @include responsive($bp-sm){
      position: initial;
    }
    display: flex;
    text-align: center;
    left: 0;

  }
  .preview--discount .preview__quantity-select {
    margin-left: .5rem;
    justify-content: center;
    text-align: center;
  }

  .preview__image ~ .preview__content {
    width: 100%;
  }
  .preview__label{
    display: none;
  }
  .preview__quantity-select {
    align-items: self-start;

    .number-input {
      justify-content: center;
    }

    .preview__input {
      min-width: initial;
    }

    .title:last-child .title__main {
      text-align: left;
    }

    .title:last-child .title__subtitle {
      left: 0;
      right: initial;
    }
  }

  .preview__added > div > div:first-child {
    flex: 1;
    text-align: left;


  }


  .make-favorite{
    //top: -2rem;
    display: none;
  }
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover,
.image-gallery-slide-wrapper {
  box-sizing: border-box;
  border: 2px solid $color-3;
}

.image-gallery-thumbnail {
  margin-bottom: 1rem;
  border: 2px solid $color-7;
}

.image-gallery-slide-wrapper.right {
  width: calc(100% - 2rem - 100px);
  @include responsive($bp-sm){
  width: calc(100% - 100px);
  }
}

.image-gallery-thumbnails-wrapper.right {
  margin-left: 1rem !important;
}

.image-gallery-thumbnails {
}

.image-gallery-slide {
  background: $color-1;
}

.image-gallery-slide .image-gallery-image {
  max-width: 40%;
  padding: 2rem;
}