html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password] {
  outline: none;
  border: none;
  border-radius: 5px;
  border: 0;
  padding: 1rem;
  transition: border-color 0.2s;
  box-sizing: border-box;
}

select {
  padding: 0.5rem 0.75rem;
  border-radius: 0;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  border: 0;
  min-height: 7.5rem;
  padding: 1rem;
  transition: border-color 0.2s;
  resize: none;
}

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}
[data-tooltip]:before, [data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 0.5rem;
  margin-left: -80px;
  padding: 0.5rem;
  width: 160px;
  background-color: rgba(12, 18, 23, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2;
}
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  width: 0;
  border-top: 0.5rem solid rgba(12, 18, 23, 0.9);
  border-right: 0.5rem solid transparent;
  border-left: 0.5rem solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

[data-tooltip-visible]:before, [data-tooltip-visible]:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}
@media only screen and (max-width: 56.25em) {
  html {
    font-size: 13.6px;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 12.2px;
  }
}

#root ~ iframe {
  display: none;
}

body {
  font-weight: normal;
  line-height: 1.6;
  min-height: 100vh;
  font-family: CI;
  margin-top: 7rem;
}
body ::selection {
  background: #FED33C;
}

strong {
  font-weight: bold;
}

a:not(.button),
a:not(.button):visited {
  color: #0C1217;
  transition: color 0.1s ease-out;
}
a:not(.button):hover,
a:not(.button):visited:hover {
  color: #FF9F00;
}

.container {
  background: #F5F5F5;
}

.bg-container {
  background: #F5F5F5;
  margin-top: -2rem;
}
.bg-container--transparent {
  background: initial;
}
@media only screen and (max-width: 37.5em) {
  .bg-container {
    margin-top: 0;
  }
}
.bg-container.contained-page {
  padding-top: 4rem;
}

.contained-page {
  padding: 2rem 4rem;
}
@media only screen and (max-width: 37.5em) {
  .contained-page {
    padding: 2rem 1rem;
  }
}

.wrap-when-more-than-2 {
  flex-wrap: wrap;
}
.wrap-when-more-than-2 > .button {
  width: calc(50% - 0.75rem) !important;
}
.wrap-when-more-than-2 > .button:nth-last-child(n+3),
.wrap-when-more-than-2 > .button:nth-last-child(n+3) ~ * {
  width: initial !important;
  margin-bottom: 0.5rem;
}

.wrap-when-more-than-3 {
  flex-wrap: wrap;
}
.wrap-when-more-than-3 > .button {
  width: initial !important;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 37.5em) {
  .wrap-when-more-than-2,
  .wrap-when-more-than-3 {
    flex-direction: column;
    margin: 2rem 0;
  }
  .wrap-when-more-than-2 > .button:nth-last-child(n+3),
  .wrap-when-more-than-2 > .button:nth-last-child(n+3) ~ *:not(.create-order),
  .wrap-when-more-than-2 > .button,
  .wrap-when-more-than-3 > .button:nth-last-child(n+3),
  .wrap-when-more-than-3 > .button:nth-last-child(n+3) ~ *:not(.create-order),
  .wrap-when-more-than-3 > .button {
    margin-bottom: 0.5rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 80% !important;
    padding: 1.75rem 2.5rem;
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .hide-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 37.5em) {
  .expand-mobile {
    width: 100%;
  }
}

.show-mobile {
  display: none !important;
}
@media only screen and (max-width: 37.5em) {
  .show-mobile {
    display: block !important;
  }
}

.infinite-scroll-component {
  padding-top: 10rem;
  margin-top: -10rem;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #0C1217;
}

::-webkit-scrollbar-thumb {
  background-color: #FF9F00;
}


