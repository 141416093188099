@import "../../assets/scss/variables";

.create-template-modal {
  input {
    padding: 0;
    padding-bottom: .5rem;
    border-bottom: 1px solid $color-3;
    width: 100%;
    border-radius: 0;
  }

  .success-message {
    padding: .5rem 1rem;
    border: 1px solid $color-success;
    margin: .5rem 0;
    color: $color-success;
    font-weight: $font-weight-bold;

    a {
      font-weight: initial;
      margin: 0 .5rem;
    }
  }
}