.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  z-index: 10000;
  padding: 4rem 25%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: transform 0.25s ease-out;
}
.search-popup--visible {
  transform: translateY(0);
}
.search-popup__background_left {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 50%;
}
.search-popup__background_right {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
}
.search-popup__close {
  position: absolute;
  top: 0;
  right: 0;
}
.search-popup__close svg {
  width: 1.5rem;
}

