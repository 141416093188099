@import "../../assets/scss/variables";

.display-tiles .infinite-scroll-component {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-gap: .5rem;
  grid-gap: 1rem;

  @include responsive($bp-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include responsive($bp-sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  //grid-auto-rows: 1fr;

  > div {
    display: flex;
    //flex-direction: column;
  }

  > div > .preview__wrapper {
    display: initial;
    width: 100%;
    margin-bottom: 0;
    font-size: .85rem;
  }

  //
  .preview__input {
    margin: 0 1rem;
  }

  .preview--discount {
    margin-top: 0;
  }
}