@media print {
  .menu {
    display: none;
  }
}
.menu--visible .menu__content {
  transform: translateY(0);
}
.menu__content {
  transform: translateY(-100%);
  z-index: 9998;
  position: fixed;
  left: 0;
  right: 0;
  background: #0C1217;
  transition: all 0.8s;
  display: flex;
}
@media only screen and (max-width: 56.25em) {
  .menu__content {
    max-height: 65vh;
    overflow: auto;
  }
}
.menu__container {
  width: 100%;
  position: relative;
  color: #FFFFFF;
}
.menu__container .close-icon {
  position: absolute;
  top: 0;
  left: 0;
  color: #FFFFFF;
}
.menu__container .close-icon svg {
  width: 1.5rem;
  height: 1rem;
}
.menu__container--first {
  padding: 2rem 15%;
}
.menu__container--first .links {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
.menu__link--default {
  text-align: left;
  padding: 0;
  box-sizing: border-box;
  background: transparent;
  border: none;
  font-size: 1rem;
  display: block;
  color: #FFFFFF !important;
  text-decoration: none;
  font-family: CI Special;
  margin-bottom: 1rem;
  cursor: pointer;
}
.menu__link--default:hover {
  color: #FF9F00 !important;
}
.menu__link--tile {
  position: relative;
  padding: 0;
  box-sizing: border-box;
  border: none;
  font-size: 1rem;
  display: block;
  color: #FFFFFF !important;
  text-decoration: none;
  font-family: CI Special;
  cursor: pointer;
  height: 110px;
  background: #414141;
  text-align: center;
  overflow: hidden;
}
.menu__link--tile::before {
  content: "";
  z-index: 1;
  background: rgba(12, 18, 23, 0.25);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.menu__link--tile .tile-background {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.8s ease-out;
}
.menu__link--tile:hover .tile-background {
  transform: scale(1.05);
}
.menu__link--tile span {
  z-index: 2;
  font-size: 1.25rem;
  position: relative;
  padding: 0.25rem;
}
.menu__link--tile-with-corner {
  position: relative;
  overflow: hidden;
  height: 15rem;
}
.menu__link--tile-with-corner img:nth-child(1) {
  position: absolute;
  width: 100%;
  bottom: -1rem;
  left: -3rem;
  z-index: 1;
}
.menu__link--tile-with-corner img:nth-child(2) {
  top: -2.5rem;
  right: -2.5rem;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.menu__link--tile-with-corner span {
  font-size: 1.5rem;
}
.menu__container--second {
  padding: 2rem 15% 1rem 15%;
}
.menu__container--second .links {
  align-self: baseline;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media only screen and (max-width: 68.75em) {
  .menu__container--second .links {
    column-count: initial;
  }
}
.menu__container--second .divider {
  border-color: #FFFFFF;
  background: #FFFFFF;
}
.menu__container--second .inner-menu-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 5rem;
}
.menu .title {
  font-size: 0.75rem;
  color: #FF9F00;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 37.5em) {
  .menu {
    display: none;
  }
}


