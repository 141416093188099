@import "../../assets/scss/variables";

.title {
  font-size: 5rem;
  font-family: $font-2;
  display: flex;
  line-height: 1;
  position: relative;

  &--h1 {
    font-size: 4rem;
  }

  &--h2 {
    font-size: 3rem;
  }

  &--h3 {
    font-size: 2rem;

    @include responsive($bp-sm) {
      font-size: 1.62rem;
    }
  }

  &--h4 {
    font-size: 1.5rem;
  }

  &--h5 {
    font-size: 1.25rem;

    @include responsive($bp-sm) {
      font-size: 1.4rem;
    }
  }

  &--body {
    font-size: 1rem;
  }

  &--disabled {
    color: $color-7;
  }

  &--crossed {
    text-decoration: line-through;
    color: $color-error;
    font-weight: $font-weight-default;
    font-size: .75rem;
    font-style: italic;
    padding-right: 0.3rem;
  }

  &--with-action {
    cursor: pointer;
  }

  &__main{
    width: 100%;
  }

  &__sup {
    @include sup;
    margin-top: 3px;
    font-size: 35%;
  }

  &--body &__sup {
    margin-top: 0;
    font-size: .75rem;
  }

  &--h4 &__sup {
    font-size: .75rem;
  }

  &__subtitle {
    color: $color-6;
    font-size: .75rem;
    font-family: $font-1;
    font-weight: $font-weight-default;
    position: absolute;
    bottom: -1rem;
    left: 0;
    white-space: nowrap;
  }

  &--invert-sup &__sup {
    order: 1;
    margin-left: 0;
  }

  &--invert-sup &__main {
    order: 2;
    margin-left: .5rem;
  }

  &--default {
    font-family: $font-1;
    font-weight: $font-weight-bold;
  }
}