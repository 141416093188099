.divider {
  border-width: 2px;
  border-color: #0C1217;
  background: #0C1217;
}
.divider--thin {
  border: none;
  background: transparent;
  height: 2px;
}
.divider--thin::before {
  background: #0C1217;
  content: "";
  display: block;
  height: 1px;
  text-align: center;
}
.divider--spacing-top-1 {
  margin-top: 1rem;
}
.divider--spacing-top-2 {
  margin-top: 1.5rem;
}
.divider--spacing-below-1 {
  margin-bottom: 1rem;
}
.divider--spacing-below-2 {
  margin-bottom: 1.5rem;
}


