@import "../../assets/scss/variables";

.searchResults-page {
  position: relative;

  > div:first-child {
    padding-top: 10%;
    background: $color-5;
    margin-bottom: -3rem;
    > img {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -55%;
      width: 100%;
    }
  }

  > div {
    position: relative;
  }

  &__meta {
    text-align: right;
    color: $color-5;
    font-size: .75rem;
    font-weight: $font-weight-bold;
    margin-bottom: .5rem;
  }

  .search-input {
    max-width: 30rem;
    margin-top: 2rem;
  }

  .search-input input {
    border-bottom: 3px solid $color-3;
    font-weight: $font-weight-bold;
  }

}