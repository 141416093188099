@import "../../assets/scss/variables";

.select-range {
  margin-top: 1rem;
  padding: 1rem;
  background: $color-1;
  display: inline-flex;
  align-items: center;

  .title{
    margin-right: 2rem;

  }
  .button{
    margin-left: 2rem;
  }


  @include responsive($bp-sm) {
    display: flex;
    flex-direction: column;

    .title,
    .react-daterange-picker,
    .button{
      display: block;
      width: 100%;
      margin: 0;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    .title + div{
      width: 100%;
    }

    .react-daterange-picker__wrapper{
        text-align: center
    }
  }
}