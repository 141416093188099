@import "../../assets/scss/variables";

.divider {
  border-width: 2px;
  border-color: $color-3;
  background: $color-3;

  &--thin {
    border: none;
    background: transparent;
    height: 2px;

    &::before {
      background: $color-3;
      content: '';
      display: block;
      height: 1px;
      text-align: center;
    }
  }

  @include spacing
}