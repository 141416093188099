@import "./variables";

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  outline: none;
  border: none;
  border-radius: 5px;
  border: 0;
  padding: 1rem;
  transition: border-color 0.2s;
  box-sizing: border-box;
}

select {
  padding: .5rem .75rem;
  border-radius: 0;
}

textarea {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 0;
  border: 0;
  min-height: 7.5rem;
  padding: 1rem;
  transition: border-color 0.2s;
  resize: none;
}