.list__item-subtitle {
  font-family: CI Special;
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  text-align: right;
}
@media only screen and (max-width: 56.25em) {
  .list__item-subtitle {
    font-size: 0.95rem;
    width: 20%;
  }
}
@media only screen and (max-width: 37.5em) {
  .list__item-subtitle {
    font-size: 1rem;
    width: 20%;
  }
}
.list__item-subtitle-primary {
  color: #FF9F00;
}
.list__item-subtitle-separator {
  margin: 0 0.5rem;
}


