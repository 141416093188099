.order-status {
  font-size: 0.85rem;
  font-weight: bold;
}
.order-status--0 {
  color: #0C1217;
  text-decoration: underline;
}
.order-status--1 {
  color: #26BC7D;
}
.order-status--2 {
  color: #BBBDBD;
}


