.home {
  margin-top: -2rem;
}
@media only screen and (max-width: 37.5em) {
  .home {
    margin-top: 0;
  }
}
.home__order-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.home__top {
  background-color: #FFFFFF;
  overflow: hidden;
  position: relative;
}
.home__top .button--action:hover {
  background: #FF9F00;
  color: #0C1217;
}
.home__top .button--action:active {
  box-shadow: none;
}
.home__top > img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
}
.home__top > img:nth-child(1) {
  top: -4rem;
}
.home__top > img:nth-child(2) {
  bottom: -4rem;
}
.home__top > div {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a b";
  grid-gap: 2rem;
  padding: 2rem;
}
@media only screen and (max-width: 68.75em) {
  .home__top > div {
    display: block;
  }
}
.home__middle {
  padding: 4rem;
}
@media only screen and (max-width: 56.25em) {
  .home__middle > .util {
    flex-direction: column;
    align-items: baseline;
  }
}
.home__middle > .util .title {
  margin-right: 2rem;
}

