.footer {
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem;
  position: relative;
}
.footer__left {
  position: relative;
}
.footer__left a {
  margin-right: 2rem;
}
.footer__right {
  position: relative;
  text-align: right;
}
.footer__right button, .footer__right a {
  border: none;
  background: transparent;
  margin-left: 1rem;
}
.footer p {
  font-size: 0.75rem;
}
.footer > img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  top: -2rem;
}

