.formGroup {
  position: relative;
  margin-bottom: 1rem;
}
.formGroup__label {
  transition: 0.2s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  font-size: 1rem;
  cursor: text;
  width: 100%;
}
.formGroup > *:nth-child(1)::placeholder {
  color: transparent;
}
.formGroup > *:nth-child(1):not(:placeholder-shown) ~ .formGroup__label {
  top: -0.25rem;
  font-size: 0.75rem;
  transform: translateY(0);
}

