@import "../../assets/scss/variables";

.reports-page {

  &__top {
    position: relative;
    overflow: hidden;
    background: $color-1;
    padding-bottom: 4rem;
    @include responsive($bp-md) {
      background: transparent;
    }

    > img {
      position: absolute;
      left: 0;
      object-fit: cover;
      bottom: 0;
      height: 100%;
      width: 55vw;
      @include responsive($bp-md) {
        width: 50vw;
      }
      @include responsive($bp-sm) {
        display: none;
      }
    }

    > div {
      position: relative;
    }
  }

  &__bottom {
    position: relative;
    overflow: hidden;
    padding-top: 8rem;
    margin-top: -4rem;

    > img {
      top: 1vh;
      right: 0;
      width: 100%;
      position: absolute;

      @media (screen and max-width: $bp-lg) {
        top: 2rem;
      }
      @media (screen and max-width: $bp-sm) {
        display: none;
      }
    }

    > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      @include responsive($bp-sm) {
        display: block;
      }
    }
  }


  &__main {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
            "a a a b b b";
    grid-gap: 2rem;

    @include responsive($bp-sm) {
      display: block;
    }

    & > *:nth-child(1) {
      grid-area: a;
      //max-width: 80%;

      @include responsive($bp-sm) {
        max-width: initial;
        margin-bottom: 2rem;
      }

      a{
        //color: $color-2;
      }
      a:hover {
        color: $color-4;
      }
    }

    & > *:nth-child(2) {
      grid-area: b
    }
  }
}