.select-store {
  font-size: 0.65rem;
}
@media only screen and (max-width: 37.5em) {
  .select-store {
    overflow: hidden;
    position: relative;
    padding: 1.25rem 1rem;
  }
}

