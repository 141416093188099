@import "../../../../assets/scss/variables";

.list__item-subtitle {
  font-family: $font-2;
  margin-bottom: .5rem;
  font-size: .85rem;
  text-align: right;

  @include responsive($bp-md) {
    font-size: 0.95rem;
    width: 20%;
  }

  @include responsive($bp-sm) {
    font-size: 1rem;
    width: 20%;
  }

  &-primary {
    color: $color-2;
  }

  &-separator {
    margin: 0 .5rem;
  }
}