.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  height: 5rem;
  position: fixed;
  overflow: hidden;
  padding: 0 1rem;
  background: #0C1217;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header--mobile {
  display: none;
}
@media only screen and (max-width: 37.5em) {
  .header {
    display: none;
  }
  .header--mobile {
    display: flex;
    height: 7rem;
  }
}
.header--mobile .header__left {
  position: relative;
  flex: 1;
  align-self: stretch;
  padding: 0;
}
.header--mobile .header__left > img {
  position: absolute;
  left: -1rem;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 90%;
  object-fit: cover;
}
.header--mobile .header__left > div {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  color: #0C1217 !important;
}
.header--mobile .header__left > div > * {
  margin-right: 2rem;
  color: inherit;
}
.header--mobile .header__right img {
  right: 1rem;
}
.header__info {
  font-size: 0.65rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: flex;
}
.header__info > * {
  margin-right: 0.25rem;
  max-width: 140px;
}
.header__info select {
  background: transparent;
  color: #FFFFFF;
  border: none;
  padding: 0;
  margin-left: 0.5rem;
}
.header__info select option {
  color: #0C1217;
}
.header__left .button {
  padding: 0;
}
.header__left .button:hover, .header__left .button:focus {
  background: transparent;
}
.header__middle {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  z-index: 1;
}
.header__middle a,
.header__middle button {
  cursor: pointer !important;
  background: transparent;
  border: none;
  font-size: 1rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  color: #FFFFFF !important;
  text-decoration: none;
  font-family: CI Special;
}
.header__middle a span :hover::after,
.header__middle button span :hover::after {
  width: 100%;
}
.header__middle a span ::after,
.header__middle button span ::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #FFFFFF;
  transition: width 0.3s;
}
.header__middle a:hover,
.header__middle button:hover {
  color: #FFFFFF;
}
.header__middle a .badge,
.header__middle button .badge {
  margin-top: -2px;
}
.header__right {
  color: #0C1217;
  display: flex;
}
.header__right img {
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}
.header__right > div {
  margin-left: auto;
  position: relative;
}
.header__right .button {
  padding: 0;
  color: inherit;
}
.header__right a:hover {
  color: #0C1217 !important;
}
.header__logo {
  width: 5rem;
  height: auto;
}
.header__left, .header__right {
  flex: 0 1 25%;
}
@media only screen and (max-width: 56.25em) {
  .header__left, .header__right {
    flex: 0 1 20%;
  }
}


