.list__item {
  display: block;
  padding: 1rem 0 1rem 0;
  border-top: 2px solid #0C1217;
  overflow: hidden;
  position: relative;
}
.list__item--contained {
  margin-left: 4rem;
  margin-right: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .list__item--contained {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.list__item--no-borders {
  border-top: none !important;
  border-bottom: none !important;
}
.list__item--no-borders + .list__item {
  border-top: none !important;
}
.list__item img {
  max-width: 100%;
}


