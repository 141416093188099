.orders-page {
  position: relative;
  overflow: hidden;
  margin-top: -2rem;
}
@media only screen and (max-width: 68.75em) {
  .orders-page {
    background: #F5F5F5;
    padding: 2rem 1rem;
  }
}
.orders-page .background {
  position: absolute;
  object-fit: cover;
  top: -2rem;
  left: 0;
  bottom: 0;
  right: 0;
  height: 120%;
  width: 55%;
}
@media only screen and (max-width: 68.75em) {
  .orders-page .background {
    left: initial;
    bottom: initial;
    right: initial;
    object-fit: initial;
    transform: rotate(270deg);
    width: 100%;
    height: 109vw;
    top: -3rem;
    background: #F5F5F5;
  }
}
.orders-page .background + div {
  position: relative;
}
@media only screen and (max-width: 68.75em) {
  .orders-page .background + div {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 68.75em) {
  .orders-page .wrapper-1 {
    margin-left: -1rem;
    margin-right: -1rem;
    position: relative;
    overflow: hidden;
    background: #FF9F00;
    margin-top: -0.5%;
    margin-bottom: -2.5%;
  }
  .orders-page .wrapper-1 .background.show-mobile + div {
    padding: 2rem 1rem;
    padding-top: 25%;
  }
}
.orders-page__main {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "a a b b b b";
  grid-gap: 2rem;
}
.orders-page__main > *:nth-child(1) {
  grid-area: a;
}
.orders-page__main > *:nth-child(2) {
  grid-area: b;
}
@media only screen and (max-width: 68.75em) {
  .orders-page__main {
    grid-template-areas: "b b b b b b" "a a a a a a";
  }
}
@media only screen and (max-width: 68.75em) {
  .orders-page table {
    border: none;
  }
}
.orders-page tr {
  background: #FFFFFF;
}
@media only screen and (max-width: 56.25em) {
  .orders-page tr {
    display: grid;
    margin-bottom: 1rem;
    grid-template-columns: repeat(4, auto);
    border: 1px solid #0C1217;
  }
  .orders-page tr > td {
    display: flex;
    border: none;
    justify-content: center;
  }
  .orders-page tr > td:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .orders-page tr > td:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 5;
    border-left: 1px solid #0C1217;
  }
  .orders-page tr > td:nth-child(5), .orders-page tr > td:nth-child(6) {
    border-top: 1px solid #0C1217;
    align-items: center;
    margin-bottom: -1px;
  }
}
.orders-page .current-order {
  background: #FED33C;
}
.orders-page .current-order a {
  color: #0C1217 !important;
}
.orders-page .submit-order {
  background: #e6e6e6;
}


