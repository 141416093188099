.billing-info {
  padding-bottom: 0.5rem;
  display: flex;
  overflow: auto;
}
.billing-info .title {
  margin-bottom: 0.5rem;
}
.billing-info > div {
  margin-right: 2rem;
  flex: 1 0 10rem;
}

