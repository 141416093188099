@import "../../assets/scss/variables";

.order-page {

  .info .util {

  }

  &__bottom {
    display: flex;
  }

  &__bottom_left {
    width: 100%;

  }

  &__notes {
    max-width: 25rem;
  }

}

@media print {
  .order-page {
    background: transparent;
    //background: red!important;
    margin-top: -8rem;
  }

  .order-page__bottom {
    flex-direction: column;
  }

  .order-page__notes {
    max-width: initial;
  }

  .preview {
    padding: .5rem .5rem .75rem 0;
  }

  .preview__info {
    padding: 0 .5rem 0 .5rem;
    font-size: .75rem;
  }

  .title--h2 {
    font-size: 1.5rem;
  }

  .title--h4 {
    font-size: 1.05rem;
  }

  .title--body {
    font-size: .75rem;
  }

  .preview__wrapper + br,
  .preview__details,
  .button-group,
  .button {
    display: none;
    overflow: hidden !important;
    height: 0 !important;
  }
}