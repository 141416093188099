.dv-header {

}

.dv-row-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dv-row-2{
  margin-top: .5rem;
}

.dv-row-2 > * {
  width: 100%;
}

.dv-actions {
  display: flex;

  > div {
    display: flex;
    padding: 2px;
    //border-radius: 50%;
    //border: 1px solid currentColor;
    cursor: pointer;
    margin-left: .5rem;
    //border-righ: 1px solid;

    > svg {
      width: 16px;
      height: 16px;
    }
  }
}
