$color-1: #FFFFFF;
$color-2: #FF9F00;
$color-3: #0C1217;
$color-4: #633025;
$color-5: #414141;
$color-6: #858585;
$color-7: #BBBDBD;
$color-8: #F5F5F5;
$color-background: #F5F5F5;
$color-success: #26BC7D;
$color-warning: #FED33C;
$color-error: #FF0000;

$base-unit: 10px;

$bp-xl: 75em; // 1200px
$bp-lg: 68.75em; // 1100px
$bp-md: 56.25em; // 900px
$bp-sm: 37.5em; // 600px
$bp-xs: 31.25em; // 500px

$font-1: CI;
$font-2: CI Special;

$font-weight-default: normal;
$font-weight-bold: bold;

$header-height: 5rem;

$z-index-header: 9999;
$z-index-modal: 10000;

@mixin responsive($bp) {
  @media only screen and (max-width: $bp) {
    @content;
  }
}

@mixin sup() {
  align-self: baseline;
  margin-left: .5rem;
  font-family: $font-2;
  font-size: .75rem;
  font-weight: $font-weight-default;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

@mixin spacing() {
  &--spacing-top-1 {
    margin-top: 1rem;
  }

  &--spacing-top-2 {
    margin-top: 1.5rem;
  }

  &--spacing-below-1 {
    margin-bottom: 1rem;
  }

  &--spacing-below-2 {
    margin-bottom: 1.5rem;
  }
}

@mixin scrollbar($thumb-color, $track-color, $width) {
  ::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
  ::-webkit-scrollbar-track {
    background: $track-color;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }
}

@mixin hover-underline($color) {
  :hover::after {
    width: 100%;
  }
  ::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: $color;
    transition: width .3s;
  }
}