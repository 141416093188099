@import "../../../assets/scss/variables";

.top-orders {
  max-width: 30vw;
  @include responsive($bp-md) {
    max-width: initial;
  }

  canvas {
    //margin-top: -10vh;
    //margin-top: -20%;
  }
}