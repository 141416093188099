.searchResults-page {
  position: relative;
}
.searchResults-page > div:first-child {
  padding-top: 10%;
  background: #414141;
  margin-bottom: -3rem;
}
.searchResults-page > div:first-child > img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -55%;
  width: 100%;
}
.searchResults-page > div {
  position: relative;
}
.searchResults-page__meta {
  text-align: right;
  color: #414141;
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.searchResults-page .search-input {
  max-width: 30rem;
  margin-top: 2rem;
}
.searchResults-page .search-input input {
  border-bottom: 3px solid #0C1217;
  font-weight: bold;
}


