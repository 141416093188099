@import "../../assets/scss/variables";

.quickView {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: $z-index-modal;
  width: 100%;
  height: 100%;
  background: rgba($color-3, 0.6);
  overflow: auto;

  &__content {
    position: absolute;
    left: 0;
    height: 100%;
    width: auto;
    overflow: auto;
    background: $color-background;
    transition: min-width 0.2s ease-out;
    display: flex;
  }

  &__container {
    position: relative;
    min-width: 12.5rem;
    padding: 4rem 4rem;
    max-width: 60vw;
    @include responsive($bp-lg) {
      max-width: 80vw;
    }
    @include responsive($bp-sm) {
      max-width: 100vw;
    }
  }

  &__corner {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;

    .button {
      position: relative;
      z-index: 2;
      color: $color-1;

      svg {
        width: 1.5rem;
      }
    }

    img {
      z-index: 1;
      position: absolute;
      right: 0;
      width: 12rem;
    }
  }
}