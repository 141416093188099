@import "../../assets/scss/variables";

.dot {
  width: .75rem;
  height: .75rem;
  background: $color-1;
  border-radius: 50%;

  display: inline-block;
  flex: none;

  &--2 {
    background: $color-2;
  }
  &--4 {
    background: $color-4;
  }
}