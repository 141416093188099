.list {
  max-height: 600px;
  overflow: auto;
}
.list__item:first-child {
  border-top: none;
}
.list--fullHeight {
  max-height: initial;
}
.list--dense .list__item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

