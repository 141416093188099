.fab {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 0 0 auto;
}
.fab svg {
  width: 1.5rem;
}
.fab--variant-1 {
  background: #FFFFFF;
}
.fab--variant-2 {
  background: #FF9F00;
}
.fab--variant-3 {
  background: #0C1217;
}
.fab--variant-4 {
  background: #633025;
}
.fab--variant-5 {
  background: #414141;
}
.fab--variant-6 {
  background: #858585;
}
.fab--variant-7 {
  background: #BBBDBD;
}
.fab--variant-8 {
  background: #F5F5F5;
}


