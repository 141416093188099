.report1-page .kpi {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #0C1217;
  color: #FFFFFF;
  margin: 4px 0;
}
.report1-page .kpi div {
  min-width: 260px;
  text-align: center;
}
.report1-page .kpi div:first-child {
  color: #FF9F00;
}
@media (max-width: 37.5em) {
  .report1-page .kpi,
  .report1-page .kpi div {
    width: 100%;
  }
}
.report1-page .table-wrapper {
  display: block;
  overflow: auto;
  margin-bottom: 1rem;
}
.report1-page table {
  width: 100%;
  border-collapse: separate;
  background: #FFFFFF;
}
.report1-page tr:nth-child(odd) {
  background-color: #F5F5F5;
}
.report1-page th {
  border-left: 1px solid #414141;
}
.report1-page th,
.report1-page td {
  border-bottom: 1px solid #414141;
  padding: 0.5rem;
  font-size: 0.75rem;
}
.report1-page th:first-child,
.report1-page td:first-child {
  border-left: 1px solid #414141;
}
.report1-page th:last-child,
.report1-page td:last-child {
  border-right: 1px solid #414141;
}
.report1-page tr:first-child > th,
.report1-page tr:first-child > td {
  border-top: 1px solid #414141;
}
.report1-page thead th,
.report1-page thead td {
  background: #0C1217;
  color: #FFFFFF;
}
.report1-page tfoot td {
  background: #FFFFFF;
  border-top: none !important;
  font-family: CI Special;
  color: #0C1217;
  font-size: 1.25rem;
}
.report1-page-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


