.search-input {
  position: relative;
  color: #0C1217;
  display: flex;
  align-items: center;
}
.search-input input {
  padding: 0.5rem 3rem 0.5rem 0;
  border-bottom: 1px solid #0C1217;
  border-radius: 0;
  width: 100%;
  background: transparent;
}
.search-input .button {
  position: absolute;
  right: 0;
  padding-right: 0;
  color: inherit;
}
.search-input--filled {
  border: 1px solid black;
  min-width: 300px;
  background-color: #FFFFFF;
  padding: 0.175rem 1rem;
}
.search-input--filled input {
  background-color: #FFFFFF;
  border-bottom: none;
}
.search-input--filled .button {
  right: 1.5rem;
}


