.checkbox {
  display: flex;
  align-items: center;
}
.checkbox label {
  font-size: 0.75rem;
  cursor: pointer;
}
@media only screen and (max-width: 37.5em) {
  .checkbox label {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .checkbox label {
    font-size: 1.2rem;
  }
}

