@import "../../assets/scss/variables";

.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $color-1;
  z-index: $z-index-modal;
  padding: 4rem 25%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition: transform .25s ease-out;

  &--visible {
    transform: translateY(0);
  }

  &__background_left {
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 50%;
  }

  &__background_right {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 50%;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;

    svg {
      width: 1.5rem;
    }
  }
}