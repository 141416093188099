.menu-old {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: rgba(12, 18, 23, 0.6);
}
.menu-old__content {
  position: absolute;
  left: 0;
  height: 100%;
  width: auto;
  overflow: auto;
  background: #0C1217;
  transition: min-width 0.2s ease-out;
  display: flex;
}
.menu-old__container {
  position: relative;
  color: #FFFFFF;
}
.menu-old__container .links {
  min-width: 18rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem 2rem 3rem;
}
.menu-old__container .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: #FFFFFF;
}
.menu-old__container .close-icon svg {
  width: 1.5rem;
}
.menu-old__container--first .background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 15rem;
  text-align: right;
  margin-left: auto;
}
.menu-old__container--second .background:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.menu-old__container--second .background:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
}
.menu-old__link {
  text-align: left;
  padding: 0;
  box-sizing: border-box;
  background: transparent;
  border: none;
  font-size: 1rem;
  display: block;
  color: #FFFFFF !important;
  text-decoration: none;
  font-family: CI Special;
  margin-bottom: 1rem;
  cursor: pointer;
}
.menu-old__link:hover {
  color: #FF9F00 !important;
}
.menu-old .title {
  font-size: 0.75rem;
  color: #FF9F00;
  margin-bottom: 1.5rem;
}
.menu-old--mobile {
  display: none;
}
@media only screen and (max-width: 37.5em) {
  .menu-old {
    display: none;
  }
  .menu-old--mobile {
    display: flex;
  }
}
.menu-old--mobile .menu-old__content {
  display: block;
  width: 100%;
  height: 100%;
}
.menu-old--mobile .menu-old__link {
  font-size: 1.5rem;
}
.menu-old--mobile .menu-old__container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0C1217;
  display: flex;
  flex-direction: column;
}
.menu-old--mobile .menu-old__container .util {
  padding: 1.5rem;
}
.menu-old--mobile .menu-old__container .title {
  font-size: 1.25rem;
}
.menu-old--mobile .menu-old__container select {
  overflow: hidden;
  position: relative;
  background: transparent;
  color: #FFFFFF;
  border: none;
  font-size: 1rem;
}
.menu-old--mobile .menu-old__container .links {
  padding: 5rem;
  font-size: 1.5rem;
}
.menu-old--mobile .menu-old__container .util {
  position: relative;
}
.menu-old--mobile .menu-old__container .close-icon {
  position: initial;
}
.menu-old--mobile .menu-old__container .close-icon svg {
  width: 2.5rem;
}
.menu-old--mobile .menu-old__container .button--action {
  background-color: #FF9F00;
  color: #0C1217;
  width: 100%;
  padding: 2rem;
  font-size: 1.5rem;
  max-width: 80%;
  margin: auto;
  display: block;
}


