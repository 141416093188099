@import "../../assets/scss/variables";

.pure-material-progress-linear {
  position: fixed;
  z-index: $z-index-modal;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  appearance: none;
  border: none;
  height: 0.15rem;
  color: rgb(var(--pure-material-primary-rgb, $color-2));
  background-color: rgba(var(--pure-material-primary-rgb, $color-3), 0.12);
  font-size: 16px;
}

.pure-material-progress-linear::-webkit-progress-bar {
  background-color: transparent;
}

.pure-material-progress-linear::-webkit-progress-value {
  background-color: currentColor;
  transition: all 1.5s;
}

.pure-material-progress-linear::-moz-progress-bar {
  background-color: currentColor;
  transition: all 1.5s;
}

.pure-material-progress-linear::-ms-fill {
  border: none;
  background-color: currentColor;
  transition: all 1.5s;
}

.pure-material-progress-linear:indeterminate {
  background-size: 200% 100%;
  background-image: linear-gradient(to right, transparent 50%, currentColor 50%, currentColor 60%, transparent 60%, transparent 71.5%, currentColor 71.5%, currentColor 84%, transparent 84%);
  animation: pure-material-progress-linear 4s infinite linear;
}

.pure-material-progress-linear:indeterminate::-moz-progress-bar {
  background-color: transparent;
}

.pure-material-progress-linear:indeterminate::-ms-fill {
  animation-name: none;
}

@keyframes pure-material-progress-linear {
  0% {
    background-size: 200% 100%;
    background-position: left -31.25% top 0%;
  }
  50% {
    background-size: 800% 100%;
    background-position: left -49% top 0%;
  }
  100% {
    background-size: 400% 100%;
    background-position: left -102% top 0%;
  }
}
