.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #FF9F00;
  color: #0C1217;
  font-family: CI;
  font-size: 0.75rem;
  line-height: 1;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin: 0 0.25rem;
}


