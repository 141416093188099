.accordion {
  margin-bottom: 1rem;

  &__summary {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &--no-action {
      cursor: initial;
    }
  }

  &__details {
    width: 100%;
    height: auto;
  }

  &--active &__details {
    display: inline-block;
  }

  &--inactive &__details {
    display: none;
  }
}