@import "../../assets/scss/variables";

.menu {
  @media print {
    display: none;
  }

  &--visible &__content {
    transform: translateY(0);
  }

  &__content {
    transform: translateY(-100%);
    z-index: $z-index-header - 1;
    position: fixed;
    left: 0;
    right: 0;
    background: $color-3;
    transition: all 0.8s;
    display: flex;

    //@include responsive($bp-md) {
    //  max-height: 400px;
    //  overflow: auto;
    //}

    @include responsive($bp-md) {
      max-height: 65vh;
      overflow: auto;
    }
  }

  &__container {
    width: 100%;
    position: relative;
    color: $color-1;

    .close-icon {
      position: absolute;
      top: 0;
      left: 0;
      color: $color-1;

      svg {
        width: 1.5rem;
        height: 1rem;
      }
    }
  }

  &__container--first {
    padding: 2rem 15%;

    .links {
      position: relative;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }
  }

  &__link--default {
    text-align: left;
    padding: 0;
    box-sizing: border-box;
    background: transparent;
    border: none;
    font-size: 1rem;
    display: block;
    color: $color-1 !important;
    text-decoration: none;
    font-family: $font-2;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
      color: $color-2 !important
    }
  }

  &__link--tile {
    position: relative;

    padding: 0;
    box-sizing: border-box;
    border: none;
    font-size: 1rem;
    display: block;
    color: $color-1 !important;
    text-decoration: none;
    font-family: $font-2;
    cursor: pointer;
    height: 110px;
    background: $color-5;
    text-align: center;
    overflow: hidden;

    &::before {
      content: "";
      z-index: 1;
      background: rgba($color-3, .25);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

    .tile-background {
      z-index: 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .8s ease-out;
    }

    &:hover .tile-background {
      transform: scale(1.05);
    }

    span {
      z-index: 2;
      font-size: 1.25rem;
      position: relative;
      padding: .25rem;
    }
  }

  &__link--tile-with-corner {
    position: relative;
    overflow: hidden;
    height: 15rem;

    img:nth-child(1) {
      position: absolute;
      width: 100%;
      bottom: -1rem;
      left: -3rem;
      z-index: 1;
    }

    img:nth-child(2) {
      top: -2.5rem;
      right: -2.5rem;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    span {
      font-size: 1.5rem;
    }
  }

  &__container--second {
    padding: 2rem 15% 1rem 15%;

    .links {
      //column-count: 2;

      align-self: baseline;
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include responsive($bp-lg) {
        column-count: initial;
      }
    }

    .divider {
      border-color: $color-1;
      background: $color-1;
    }

    .inner-menu-container {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
      grid-gap: 5rem;
    }
  }

  .title {
    font-size: .75rem;
    color: $color-2;
    margin-bottom: .5rem;
  }

  @include responsive($bp-sm) {
    display: none;
  }

}