.table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: separate;
  border: 1px solid #0C1217;
}
.table--no-bottom {
  padding-bottom: 0;
}
.table tr:last-child td {
  border-bottom: none !important;
}
.table td {
  border: 1px solid #0C1217;
  padding: 0.5rem 1rem;
}
@media only screen and (max-width: 37.5em) {
  .table td {
    padding: 0.5rem 0.8rem;
  }
}
.table > tbody > tr > td, .table > tbody > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  vertical-align: middle !important;
}
.table tbody th {
  font-size: 20px;
  font-weight: bold;
}
.table label {
  font-weight: initial;
}
.table .validation_error {
  position: initial !important;
}
.table__wrapper {
  display: flex;
  overflow: auto;
}
.table__wrapper table tr td:first-child {
  width: 0;
  white-space: nowrap;
}
.table--has-row-action tbody tr {
  cursor: pointer;
}
.table--has-row-action tbody tr:hover {
  background: #E6EEF5;
}

.pagination {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination .pagination-inner > * {
  margin-right: 10px;
  width: initial;
  font-size: 12px;
}
.pagination .button {
  color: inherit;
  text-decoration: initial;
}
.pagination .button:last-child {
  margin-right: -0.5rem;
}

.expand-toggle {
  padding: 5px;
  font-family: CI Special;
}


