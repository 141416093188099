.card {
  position: relative;
  background: #FF9F00;
  padding: 1.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.card img {
  max-width: 60%;
  height: auto;
}
.card--light {
  color: #FFFFFF;
}
.card__corner--1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}
.card__corner--2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.card__content {
  z-index: 2;
  position: relative;
}
.card__description {
  font-size: 0.85rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 37.5em) {
  .card__description {
    font-size: 1.1rem;
  }
}
.card__action {
  text-align: right;
}
.card__pre-text {
  align-self: baseline;
  margin-left: 0.5rem;
  font-family: CI Special;
  font-size: 0.75rem;
  font-weight: normal;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-size: 0.8rem;
  margin-left: 0;
}


