@import "../../assets/scss/variables";

.badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: $color-2;
  color: $color-3;
  font-family: $font-1;
  font-size: .75rem;
  //line-height: .8;
  line-height: 1;
  //padding: .5rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  margin: 0 .25rem;
}