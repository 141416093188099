.button {
  cursor: pointer;
  background: #0C1217;
  color: #FFFFFF;
  padding: 0.75rem 3.5rem;
  font-weight: bold;
  font-family: CI;
  border-radius: 0.35rem;
  border: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.5s ease;
  text-decoration: none;
  font-size: 0.8rem;
  line-height: 1.2;
  white-space: nowrap;
  box-sizing: border-box;
}
.button i {
  font-size: 1.5rem;
}
.button:disabled {
  cursor: not-allowed;
}
.button:hover {
  background: #414141;
}
.button:active {
  transition: all 0.1s ease;
  box-shadow: inset 0 0 1rem #0C1217;
}
.button__sup {
  align-self: baseline;
  margin-left: 0.5rem;
  font-family: CI Special;
  font-size: 0.75rem;
  font-weight: normal;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.button--action {
  padding: 0.75rem 2.5rem;
  letter-spacing: 0;
  font-size: 1.25rem;
  font-family: CI Special;
  font-weight: normal;
}
@media only screen and (max-width: 56.25em) {
  .button--action {
    font-size: 1.64rem;
  }
}
.button--invert-sup .button__sup {
  order: 1;
  margin-left: 0;
}
.button--invert-sup .button__main {
  order: 2;
  margin-left: 0.5rem;
}
.button--outlined {
  background: transparent;
  border: 1px solid #FFFFFF;
}
.button--outlined:hover {
  background: rgba(255, 255, 255, 0.1);
}
.button--outlined:active {
  box-shadow: none;
}
.button--link {
  background: transparent;
  color: #0C1217;
  text-decoration: underline;
  padding-left: 0;
  padding-right: 0;
}
.button--icon {
  background: transparent;
  text-decoration: underline;
  padding: 0.5rem 1rem;
}
.button--icon:hover, .button--icon:focus, .button--link:focus, .button--link:hover {
  transition: all 0.1s ease;
  box-shadow: none;
  background: transparent;
}
.button--link:hover {
  color: #FF9F00;
}
.button__suffix svg {
  margin-left: 1rem;
  width: 1.25rem;
}
@media only screen and (max-width: 56.25em) {
  .button {
    padding: 0.75rem 2.2rem;
  }
}
@media only screen and (max-width: 37.5em) {
  .button {
    padding: 0.75rem 1.4rem;
  }
}

.button-group {
  display: flex;
  align-items: center;
}
.button-group--spacing-top-1 {
  margin-top: 1rem;
}
.button-group--spacing-top-2 {
  margin-top: 1.5rem;
}
.button-group--spacing-below-1 {
  margin-bottom: 1rem;
}
.button-group--spacing-below-2 {
  margin-bottom: 1.5rem;
}
.button-group > .button:not(:last-child) {
  margin-right: 1.5rem;
}
.button-group--dense > .button:not(:last-child) {
  margin-right: initial;
}
.button-group--expandedButtons > .button {
  width: 100%;
}
@media only screen and (max-width: 37.5em) {
  .button-group--expandedButtons {
    flex-direction: column;
  }
  .button-group--expandedButtons > .button {
    box-sizing: border-box;
    width: 100%;
    margin: 0 !important;
    margin-bottom: 0.5rem !important;
  }
}


