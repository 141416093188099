@import "../../assets/scss/variables";

.error-message {
    display: block;
    color: $color-error;
    font-size: .75rem;
    strong {
        margin-right: .5rem;
    }
    @include responsive($bp-sm) {
        font-size: 1rem;
    }

    @include responsive($bp-sm) {
        font-size: 1.1rem;
    }

}