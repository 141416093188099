@import "../../assets/scss/variables";

.search-input {
  position: relative;
  color: $color-3;
  display: flex;
  align-items: center;

  input {
    padding: .5rem 3rem .5rem 0;
    border-bottom: 1px solid $color-3;
    border-radius: 0;
    width: 100%;
    background: transparent;
  }

  .button {
    position: absolute;
    right: 0;
    padding-right: 0;
    color: inherit;
  }


  &--filled {
    border: 1px solid black;
    min-width: 300px;
    background-color: $color-1;
    padding: .175rem 1rem;
  }

  &--filled input {
    background-color: $color-1;
    border-bottom: none;
  }

  &--filled .button {
    right: 1.5rem;
  }
}