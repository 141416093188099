.spinner-container {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .loading-spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.01);
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .spinner-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}

