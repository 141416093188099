@import "../../assets/scss/variables";

.banner {
  display: flex;
  //padding: 10rem 5rem;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .button {
    font-size: 1.25rem;
  }

  & > .card:nth-child(1) {
    //margin-right: 5rem;
    margin-right: 1rem;
  }

  & > .card:nth-child(2) {
    .button {
      border-color: $color-3;
      color: $color-3;
    }
  }

  @include responsive($bp-sm) {
    flex-direction: column;
    padding: 2.5rem 1rem;
    & > .card:nth-child(1) {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }
}