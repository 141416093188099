@import "../../assets/scss/variables";

.button {
  cursor: pointer;
  background: $color-3;
  color: $color-1;
  padding: .75rem 3.5rem;
  font-weight: $font-weight-bold;
  font-family: $font-1;
  border-radius: .35rem;
  border: none;
  display: inline-flex;
  align-items: center;
  transition: all .5s ease;
  text-decoration: none;
  font-size: .8rem;
  line-height: 1.2;
  white-space: nowrap;
  box-sizing: border-box;

  i {
    font-size: 1.5rem;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: $color-5;
  }

  &:active {
    transition: all .1s ease;
    box-shadow: inset 0 0 1rem $color-3;
  }

  &__sup {
    @include sup
  }

  &--action {
    padding: .75rem 2.5rem;
    letter-spacing: 0;
    font-size: 1.25rem;
    font-family: $font-2;
    font-weight: $font-weight-default;

    @include responsive($bp-md) {
      font-size: 1.64rem;
    }
  }

  &--invert-sup &__sup {
    order: 1;
    margin-left: 0;
  }

  &--invert-sup &__main {
    order: 2;
    margin-left: .5rem;
  }

  &--outlined {
    background: transparent;
    border: 1px solid $color-1;
  }

  &--outlined:hover {
    background: rgba($color-1, 0.1);
  }

  &--outlined:active {
    box-shadow: none;
  }

  &--link {
    background: transparent;
    color: $color-3;
    text-decoration: underline;
    padding-left: 0;
    padding-right: 0;
  }

  &--icon {
    background: transparent;
    text-decoration: underline;
    padding: .5rem 1rem;
  }

  &--icon:hover,
  &--icon:focus,
  &--link:focus,
  &--link:hover {
    transition: all .1s ease;
    box-shadow: none;
    background: transparent;
  }

  &--link:hover {
    color: $color-2;
  }

  &__suffix svg {
    margin-left: 1rem;
    width: 1.25rem;
  }

  @include responsive($bp-md) {
    padding: 0.75rem 2.2rem;
  }

  @include responsive($bp-sm) {
    padding: 0.75rem 1.4rem;
  }

}

.button-group {
  display: flex;
  align-items: center;
  @include spacing;

  & > .button:not(:last-child) {
    margin-right: 1.5rem;
  }

  &--dense > .button:not(:last-child) {
    margin-right: initial;
  }

  &--expandedButtons > .button {
    width: 100%;
  }

  @include responsive($bp-sm) {

    &--expandedButtons {
      flex-direction: column;

      & > .button {
        box-sizing: border-box;
        width: 100%;
        margin: 0 !important;
        margin-bottom: .5rem!important;
      }
    }
  }

}