@import "../../assets/scss/variables";

.select-store {
  font-size: .65rem;
  @include responsive($bp-sm) {
    overflow: hidden;
    position: relative;
    //font-size: .8rem;
    padding: 1.25rem 1rem;
  }
}