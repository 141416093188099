.ft-range {
  display: flex;
  align-items: center;
}
.ft-range input:first-child {
  margin-right: 0.5rem;
}
.ft-range input {
  min-width: 10rem;
  padding: 0.25rem;
}

