.banner {
  display: flex;
  overflow: visible;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.banner .button {
  font-size: 1.25rem;
}
.banner > .card:nth-child(1) {
  margin-right: 1rem;
}
.banner > .card:nth-child(2) .button {
  border-color: #0C1217;
  color: #0C1217;
}
@media only screen and (max-width: 37.5em) {
  .banner {
    flex-direction: column;
    padding: 2.5rem 1rem;
  }
  .banner > .card:nth-child(1) {
    margin-bottom: 1rem;
    margin-right: 0;
  }
}


